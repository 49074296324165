<template>
  <div
    class="fixed top-0 left-0 right-0 z-40 flex items-center justify-between gap-x-6 px-6 py-2.5 sm:pr-3.5 lg:pl-8"
    style="background-color: #ffffd5"
  >
    <p class="text-sm font-body-bold leading-6 text-black">
      <a href="#" @click.prevent="redirectToStores">
        Finalisez la configuration de votre magasin&nbsp;
        <span aria-hidden="true">&rarr;</span>
      </a>
    </p>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToStores() {
      this.$router.push({
        path: "/admin/organization",
        query: {
          ...this.$route.query,
          tab: "stores",
          page: "1",
          status: "information_to_be_completed",
        },
      });
    },
  },
};
</script>
