<template>
  <div class="bg-primary-light p-2 cursor-pointer text-primary-dark" @click="redirectToChallengePage">
    <div>
      <div class="flex flex-rows justify-center items-center">
        <p class="font-body-bold uppercase text-sm justify-self-center">Challenge en cours 🏆</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToChallengePage() {
      this.$router.push("/pos/challenge");
    },
  },
};
</script>
