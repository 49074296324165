<template>
  <div class="flex justify-between px-2 my-4">
    <h1 class="text-4xl font-heading-1 tracking-tight text-gray-900">
      {{ $t("Sellers") }}
    </h1>
    <GenericButton @onClick="isSellerCreationOpen = true">{{ $t("Add") }}</GenericButton>
  </div>
  <SearchBar
    class="my-4"
    :placeholder="$t('SearchSellers')"
    :current-page="currentPage"
    :available-filters="{}"
    :selected-filters="{}"
    :initial-keywords="keywords"
    @updateKeywordSearch="updateKeywordSearch"
  />
  <SellerList :sellers="sellers" @getSellers="getSellers('')" />
  <Pagination
    :currentPage="currentPage"
    :totalPages="totalPages"
    :totalCount="totalCount"
    @changePage="handleChangePage"
  />
  <SellerCreation
    :isOpen="isSellerCreationOpen"
    @closeSellerCreation="closeSellerCreation"
    @getSellers="getSellers('')"
  />
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import SellerList from "@/components/seller/SellerList.vue";
import SearchBar from "@/components/menu/SearchBar.vue";
import SellerCreation from "@/views/pos/seller/SellerCreation.vue";

import StoreService from "@/api/services/store";
import { mapActions, mapGetters } from "vuex";
import { Pagination } from "@estaly/ui";

export default {
  components: {
    GenericButton,
    SellerList,
    SearchBar,
    SellerCreation,
    Pagination,
  },

  data() {
    return {
      sellers: [],
      totalCount: null,
      totalPages: null,
      currentPage: 1,
      keywords: "",
      isSellerCreationOpen: false,
    };
  },

  mounted() {
    this.getSellers("");
    if (this.$route.params.openSellerCreation) {
      this.isSellerCreationOpen = true;
    }
  },

  computed: {
    ...mapGetters("store", ["selectedStoreId"]),
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    handleChangePage(page) {
      this.currentPage = page;
      this.getSellers("");
    },

    updateKeywordSearch(search) {
      this.currentPage = 1;
      this.getSellers(search);
    },

    closeSellerCreation() {
      this.isSellerCreationOpen = false;
    },

    async getSellers(keywords) {
      const response = await StoreService.getSellers(this.selectedStoreId, keywords, this.currentPage);
      if (!response?.success) return;

      this.sellers = response.data.sellers;
      this.totalCount = response.data.page.totalCount;
      this.totalPages = response.data.page.totalPages;
    },
  },
};
</script>
