<template>
  <Modal :open="openModal" @close="closeBillingModal">
    <h1 class="text-center font-black text-xl">Modifier vos informations</h1>

    <div class="my-10">
      <label for="iban" class="block text-sm font-medium text-gray-700">
        {{ $t("Iban") }}
      </label>
      <FormField name="localIban" v-model="localIban" id="iban" />
    </div>

    <div class="flex justify-center">
      <button
        type="button"
        class="inline-flex items-center rounded-2xl border border-transparent text-button-font-primary bg-primary uppercase px-6 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
        @click="updateIban"
      >
        Mettre à jour
      </button>
    </div>
  </Modal>
</template>

<script>
import { Modal } from "@estaly/ui";
import FormField from "@/components/utils/FormField.vue";

export default {
  components: {
    Modal,
    FormField,
  },

  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    iban: {
      type: String,
    },
  },

  data() {
    return {
      localIban: this.iban,
    };
  },

  methods: {
    closeBillingModal() {
      this.$emit("closeBillingModal");
    },

    updateIban() {
      this.$emit("updateIban", this.localIban);
      this.closeBillingModal();
    },
  },
};
</script>
