<template>
  <div>
    <div class="px-2 my-4">
      <h1 class="text-4xl font-heading-1 tracking-tight text-gray-900">Pilotage</h1>
    </div>

    <div v-if="isSeller">
      <RetailAnalytics />
      <Loading v-if="isLoading" />
    </div>

    <div v-else>
      <TabsNavigation :tabs="tabs" class="mb-6" />
      <div class="mt-8">
        <component :is="currentTabComponent" />
      </div>
    </div>
  </div>
</template>

<script>
import RetailAnalytics from "@/components/analytics/RetailAnalytics.vue";
import GeneralAnalytics from "@/components/analytics/GeneralAnalytics.vue";

import { mapGetters, mapState } from "vuex";
import { Loading, TabsNavigation } from "@estaly/ui";

export default {
  components: {
    RetailAnalytics,
    GeneralAnalytics,
    Loading,
    TabsNavigation,
  },

  data: () => {
    return {
      isLoading: false,
      stats: null,
      total: null,
      tabs: [],
      activeTab: "general",
    };
  },

  computed: {
    ...mapGetters("store", ["selectedStoreId"]),
    ...mapGetters("auth", ["isSeller"]),
    ...mapState("auth", ["currentUser"]),

    currentTabComponent() {
      return this.activeTab === "retail" ? "RetailAnalytics" : "GeneralAnalytics";
    },
  },

  watch: {
    "$route.query.tab": {
      immediate: true,
      handler(newTab) {
        this.activeTab = newTab || "general";
      },
    },
  },

  created() {
    this.initializeTabs();
  },

  methods: {
    initializeTabs() {
      this.tabs = [
        { name: "general", displayName: "Vue d'ensemble", query: { tab: "general" } },
        { name: "retail", displayName: "Suivi des ventes", query: { tab: "retail" } },
      ];
    },
  },
};
</script>
