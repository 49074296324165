import { createRouter, createWebHistory } from "vue-router";
import Signin from "../views/public/Signin.vue";
import Signup from "../views/public/Signup.vue";
import ForgotPassword from "../views/public/ForgotPassword.vue";
import Profile from "../views/common/Profile.vue";

import PasswordReset from "../views/public/PasswordReset.vue";
import PlanDetails from "../views/public/PlanDetails.vue";

import store from "../store";
import StoreClaimDetails from "@/views/store/claim/details/StoreClaimDetails";

import HomeAdmin from "../views/admin/analytics/Analytics.vue";
import AdminPlanSales from "../views/admin/plansale/AdminPlanSales.vue";
import Invoices from "../views/admin/remuneration/Invoices.vue";
import InvoiceDetails from "../views/admin/remuneration/InvoiceDetails.vue";
import Retributions from "../views/admin/retribution/Retributions.vue";
import AdminClaimList from "@/views/admin/claim/list/AdminClaimList";
import AdminClaimDetails from "@/views/admin/claim/details/AdminClaimDetails";
import Organization from "@/views/admin/organization/Organization.vue";

import HomePos from "../views/pos/home/HomePos.vue";
import ProductList from "@/views/store/products/list/ProductList.vue";
import ProductDetails from "@/views/store/products/details/ProductDetails.vue";
import StoreClaimList from "@/views/store/claim/list/StoreClaimList.vue";
import PosClaimDetails from "@/views/pos/claim/details/PosClaimDetails.vue";
import PosClaimList from "@/views/pos/claim/list/PosClaimList.vue";
import PosPlanSales from "@/views/pos/plansale/PosPlanSales.vue";
import Analytics from "@/views/pos/analytics/Analytics.vue";
import PosSellerList from "@/views/pos/seller/PosSellerList.vue";
import StorePlanSales from "@/views/store/plansale/StorePlanSales.vue";
import PlanSaleDetails from "@/components/plansales/PlanSaleDetails.vue";
import StoreDetails from "../views/admin/store/StoreDetails.vue";
import UserList from "../views/admin/user/UserList.vue";
import PosChallenge from "@/views/pos/PosChallenge.vue";
import AdminChallenge from "@/views/admin/AdminChallenge.vue";

import OrganizationAnalytics from "../views/public/OrganizationAnalytics.vue";

import { NotFound } from "@estaly/ui";

const publicRoutes = [
  {
    path: "/signin",
    component: Signin,
  },

  {
    path: "/signup",
    component: Signup,
  },

  {
    path: "/forgot-password",
    component: ForgotPassword,
  },

  {
    path: "/password-reset/:id",
    component: PasswordReset,
  },

  {
    path: "/plan-details/:contractSku",
    component: PlanDetails,
  },

  {
    path: "/analytics",
    component: OrganizationAnalytics,
  },

  {
    path: "/404",
    component: NotFound,
  },
];

const adminRoutes = [
  {
    path: "/admin",
    component: HomeAdmin,
    meta: {
      layout: "admin-layout",
      navigation: "Management",
      permission: "admin",
    },
  },
  {
    path: "/admin/plan-sales",
    component: AdminPlanSales,
    meta: {
      layout: "admin-layout",
      navigation: "Plan sales",
      permission: "plan_sales",
    },
  },
  {
    path: "/admin/plan-sales/:id",
    component: PlanSaleDetails,
    meta: {
      layout: "admin-layout",
      navigation: "Plan sales",
      permission: "plan_sales",
    },
  },
  {
    path: "/admin/claim",
    component: AdminClaimList,
    meta: {
      layout: "admin-layout",
      navigation: "Claim List",
      permission: "claims",
    },
  },
  {
    path: "/admin/claim/:id",
    component: AdminClaimDetails,
    meta: {
      layout: "admin-layout",
      navigation: "Claim List",
      permission: "claims",
    },
  },
  {
    path: "/admin/profile",
    component: Profile,
    meta: { layout: "admin-layout" },
  },
  {
    path: "/admin/invoices",
    component: Invoices,
    meta: {
      layout: "admin-layout",
      navigation: "Remuneration",
      permission: "invoices",
    },
  },
  {
    path: "/admin/invoices/:id",
    component: InvoiceDetails,
    meta: {
      layout: "admin-layout",
      navigation: "Remuneration",
      permission: "invoices",
    },
  },
  {
    path: "/admin/retributions",
    component: Retributions,
    meta: {
      layout: "admin-layout",
      navigation: "Retribution",
      permission: "invoices",
    },
  },
  {
    path: "/admin/organization",
    component: Organization,
    meta: {
      layout: "admin-layout",
      navigation: "My organization",
      permission: "stores",
    },
  },
  {
    path: "/admin/organization/stores/:id",
    component: StoreDetails,
    meta: {
      layout: "admin-layout",
      navigation: "My organization",
      permission: "stores",
    },
  },
  {
    path: "/admin/users",
    component: UserList,
    meta: {
      layout: "admin-layout",
      navigation: "Users",
      permission: "users",
    },
  },
  {
    name: "AdminChallenge",
    path: "/admin/challenge",
    component: AdminChallenge,
    meta: {
      layout: "admin-layout",
      navigation: "Challenge",
      permission: "admin",
    },
  },
];

const platformRoutes = [
  {
    path: "/platform/plan_sales",
    component: StorePlanSales,
    meta: { layout: "platform-layout", navigation: "Plan sales" },
  },
  {
    path: "/platform/products/:id",
    component: ProductDetails,
    meta: { layout: "platform-layout", navigation: "Products" },
  },
  {
    path: "/platform/products",
    component: ProductList,
    meta: { layout: "platform-layout", navigation: "Products" },
  },
  {
    path: "/platform/claim",
    component: StoreClaimList,
    meta: {
      layout: "platform-layout",
      navigation: "Claim List",
      permission: "claims",
    },
  },
  {
    path: "/platform/claim/:id",
    component: StoreClaimDetails,
    meta: {
      layout: "platform-layout",
      navigation: "Claim List",
      permission: "claims",
    },
  },
  {
    path: "/platform/profile",
    component: Profile,
    meta: { layout: "platform-layout" },
  },
];

const posRoutes = [
  {
    path: "/pos",
    component: HomePos,
    meta: { layout: "pos-layout", navigation: "Subscription" },
  },
  {
    path: "/pos/plan-sales",
    component: PosPlanSales,
    meta: {
      layout: "pos-layout",
      navigation: "Plan sales",
      permission: "plan_sales",
    },
  },
  {
    path: "/pos/plan-sales/:id",
    component: PlanSaleDetails,
    meta: { layout: "pos-layout", navigation: "Plan sales" },
  },
  {
    path: "/pos/claims",
    component: PosClaimList,
    meta: {
      layout: "pos-layout",
      navigation: "Claim List",
      permission: "claims",
    },
  },
  {
    path: "/pos/claims/:id",
    component: PosClaimDetails,
    meta: {
      layout: "pos-layout",
      navigation: "Claim List",
      permission: "claims",
    },
  },
  {
    path: "/pos/analytics",
    component: Analytics,
    meta: {
      layout: "pos-layout",
      navigation: "Performances",
      permission: "plan_sales",
    },
  },
  {
    path: "/pos/profile",
    component: Profile,
    meta: { layout: "pos-layout" },
  },
  {
    name: "PosSellers",
    path: "/pos/sellers",
    component: PosSellerList,
    meta: {
      layout: "pos-layout",
      navigation: "Sellers",
    },
  },
  {
    name: "PosChallenge",
    path: "/pos/challenge",
    component: PosChallenge,
    meta: {
      layout: "pos-layout",
      navigation: "Challenge",
    },
  },
];

const routes = publicRoutes.concat(posRoutes, platformRoutes, adminRoutes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function needsHttpsRedirection() {
  return (
    (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "demo" || process.env.NODE_ENV === "staging") &&
    location.protocol !== "https:"
  );
}

function redirectHttps() {
  location.replace(`https:${location.href.substring(location.protocol.length)}`);
}

function redirectToDefaultRoute(next) {
  if (store.getters["auth/isAdmin"]) {
    next("/admin");
  } else if (store.getters["auth/isSellerOrResponsible"]) {
    next("/pos/plan-sales");
  } else {
    next("/signin");
  }
}

function hasPermission(to, userPermissions) {
  return to.meta.permission ? userPermissions.includes(to.meta.permission) : true;
}

router.beforeEach((to, from, next) => {
  if (needsHttpsRedirection()) {
    redirectHttps();
  } else {
    const publicPages = publicRoutes.map((route) => route.path);
    const authRequired = !to.matched.some((matched) => publicPages.includes(matched.path));
    const signedIn = store.state.auth.signedIn;

    // remove isLoading
    store.state.store.isLoading = false;

    if (!authRequired) {
      // Public routes
      next();
      return;
    }

    if (!signedIn || store.state.auth.currentUser.permissions === undefined) {
      // Signin
      next("/signin");
    } else {
      if (to.path === "/") {
        // Default route
        redirectToDefaultRoute(next);
      } else {
        if (!hasPermission(to, store.state.auth.currentUser.permissions)) {
          next(from.path);
        } else {
          if (!to.matched.length) {
            next("/404");
          } else {
            next();
          }
        }
      }
    }
  }
});

router;

export default router;
