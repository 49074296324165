import { securedAxiosInstance } from "../axios";
import { toSnakeCase } from "@estaly/ui/src/utils/data_formatter";

class SellerService {
  getSellersRanking(params) {
    return securedAxiosInstance.get("/sellers/ranking", {
      params: toSnakeCase(params),
    });
  }
}

export default new SellerService();
