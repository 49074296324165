import { securedAxiosInstance } from "../axios";
import { toCamelCase } from "@estaly/ui/src/utils/data_formatter";

class OrganizationService {
  async getOrganization(organizationId) {
    const response = await securedAxiosInstance.get(`/organizations/${organizationId}`);
    return toCamelCase(response.data);
  }

  updateOrganization(organizationId, data) {
    return securedAxiosInstance.put(`/organizations/${organizationId}`, data);
  }
}

export default new OrganizationService();
