<template>
  <div>
    <div>
      <h1 class="text-4xl font-heading-1 pl-2 tracking-tight text-gray-900 my-4">
        {{ $t("Profile") }}
      </h1>
    </div>
    <div class="space-y-6">
      <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="md:col-span-1">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              {{ $t("Personal information") }}
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              {{ $t("Use a permanent address where you can receive mail.") }}
            </p>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <VeeForm v-slot="{ handleSubmit }" v-bind:validation-schema="schema" as="div" class="w-full" ref="form">
              <form v-on:submit.prevent="handleSubmit($event, handleUpdateUser)">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-3">
                    <label for="first-name" class="block text-sm font-medium text-gray-700">
                      {{ $t("First name") }}
                    </label>
                    <Field
                      type="text"
                      name="firstName"
                      id="first-name"
                      autocomplete="given-name"
                      class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      v-model="userInfoLocal.firstName"
                    />
                    <ErrorMessage name="firstName" class="mt-2 text-sm text-red-600" />
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="last-name" class="block text-sm font-medium text-gray-700">{{ $t("Last name") }}</label>
                    <Field
                      type="text"
                      name="lastName"
                      id="last-name"
                      autocomplete="family-name"
                      class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      v-model="userInfoLocal.lastName"
                    />
                    <ErrorMessage name="lastName" class="mt-2 text-sm text-red-600" />
                  </div>

                  <div class="col-span-6 sm:col-span-4">
                    <label for="email-address" class="block text-sm font-body-bold text-gray-700">
                      {{ $t("Email address") }}
                    </label>
                    <Field
                      type="text"
                      name="email"
                      id="email-address"
                      autocomplete="email"
                      class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      v-model="userInfoLocal.email"
                    />
                    <ErrorMessage name="email" class="mt-2 text-sm text-red-600" />
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="country" class="block text-sm font-body-bold text-gray-700">{{ $t("Country") }}</label>
                    <Field
                      v-slot="{ value }"
                      name="country"
                      as="select"
                      class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      v-model="userInfoLocal.country"
                    >
                      <option
                        v-for="(country, id) in countries"
                        v-bind:key="id"
                        v-bind:value="country.code"
                        v-bind:selected="value && value.includes(country.code)"
                      >
                        {{ country.name }}
                      </option>
                    </Field>
                    <ErrorMessage name="country" class="mt-2 text-sm text-red-600" />
                  </div>

                  <div class="col-span-6">
                    <label for="address-1" class="block text-sm font-body-bold text-gray-700">
                      {{ $t("Address line 1") }}
                    </label>
                    <Field
                      type="text"
                      name="address1"
                      id="address-1"
                      autocomplete="address-line1"
                      class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      v-model="userInfoLocal.address1"
                    />
                    <ErrorMessage name="address1" class="mt-2 text-sm text-red-600" />
                  </div>

                  <div class="col-span-6">
                    <label for="address-2" class="block text-sm font-body-bold text-gray-700">
                      {{ $t("Address line 2") }}
                    </label>
                    <Field
                      type="text"
                      name="address2"
                      id="address-2"
                      autocomplete="address-line2"
                      class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      v-model="userInfoLocal.address2"
                    />
                    <ErrorMessage name="address2" class="mt-2 text-sm text-red-600" />
                  </div>

                  <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label for="city" class="block text-sm font-body-bold text-gray-700">{{ $t("City") }}</label>
                    <Field
                      type="text"
                      name="city"
                      id="city"
                      autocomplete="address-level2"
                      class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      v-model="userInfoLocal.city"
                    />
                    <ErrorMessage name="city" class="mt-2 text-sm text-red-600" />
                  </div>

                  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label for="province" class="block text-sm font-body-bold text-gray-700">
                      {{ $t("State / Province") }}
                    </label>
                    <Field
                      type="text"
                      name="province"
                      id="province"
                      autocomplete="address-level1"
                      class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      v-model="userInfoLocal.province"
                    />
                    <ErrorMessage name="region" class="mt-2 text-sm text-red-600" />
                  </div>

                  <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label for="zip-code" class="block text-sm font-body-bold text-gray-700">
                      {{ $t("ZIP / Postal code") }}
                    </label>
                    <Field
                      type="text"
                      name="zipCode"
                      id="zip-code"
                      autocomplete="postal-code"
                      class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      v-model="userInfoLocal.zipCode"
                    />
                    <ErrorMessage name="zipCode" class="mt-2 text-sm text-red-600" />
                  </div>
                </div>
                <div class="flex justify-end mt-6">
                  <button
                    type="submit"
                    class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-button-font-primary bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary uppercase"
                  >
                    {{ $t("Save") }}
                  </button>
                </div>
              </form>
            </VeeForm>
          </div>
        </div>
      </div>

      <NewPassword />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import NewPassword from "./NewPassword.vue";
import UserService from "../../api/services/user";
import { countries } from "@/utils/data";

export default {
  components: { Field, VeeForm, ErrorMessage, NewPassword },

  data: () => {
    const schema = yup.object({});

    return {
      userInfoLocal: {},
      countries,
      schema,
    };
  },

  computed: {
    ...mapState("auth", ["currentUser"]),
  },

  mounted() {
    this.userInfoLocal = this.currentUser;
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async handleUpdateUser() {
      const params = {
        first_name: this.userInfoLocal.firstName,
        last_name: this.userInfoLocal.lastName,
        email: this.userInfoLocal.email,
        country: this.userInfoLocal.country,
        city: this.userInfoLocal.city,
        zip_code: this.userInfoLocal.zipCode,
        province: this.userInfoLocal.province,
        address1: this.userInfoLocal.address1,
        address2: this.userInfoLocal.address2,
      };
      const response = await UserService.updateProfile(params);
      if (!response?.success) return;

      this.notify({
        category: "simple",
        type: "success",
        title: "Your personal utils have been updated!",
      });
      this.userInfoLocal = this.currentUser;
    },
  },
};
</script>
