<template>
  <div>
    <TabsNavigation :tabs="tabs" class="mb-6" />
    <div class="mt-8">
      <component :is="currentTabComponent" />
    </div>
  </div>
</template>

<script>
import RetailAnalytics from "@/components/analytics/RetailAnalytics.vue";
import GeneralAnalytics from "@/components/analytics/GeneralAnalytics.vue";
import { TabsNavigation } from "@estaly/ui";
import { mapState } from "vuex";

export default {
  components: {
    TabsNavigation,
    RetailAnalytics,
    GeneralAnalytics,
  },

  computed: {
    ...mapState("auth", ["currentUser"]),

    retailAnalyticsAvailable() {
      return this.currentUser.retailAnalytics;
    },

    currentTabComponent() {
      return this.activeTab === "retail" ? "RetailAnalytics" : "GeneralAnalytics";
    },
  },

  data() {
    return {
      tabs: [],
      activeTab: "general",
    };
  },

  watch: {
    "$route.query.tab": {
      immediate: true,
      handler(newTab) {
        this.activeTab = newTab || "general";
      },
    },
  },

  created() {
    this.initializeTabs();
  },

  methods: {
    initializeTabs() {
      this.tabs = [{ name: "general", displayName: "Vue d'ensemble", query: { tab: "general" } }];

      if (this.retailAnalyticsAvailable) {
        this.tabs.push({
          name: "retail",
          displayName: "Suivi des ventes",
          query: { tab: "retail" },
        });
      }
    },
  },
};
</script>
