<template>
  <div class="flex justify-between items-center">
    <p class="text-2xl font-heading-1">{{ computedTitle }}</p>
    <p class="text-4xl font-heading-1" v-if="isPresent(breakdown?.general)">
      {{ this.formatPrice(totalCommission) }}
    </p>
  </div>
  <Selector
    v-if="isPresent(filters.sellerName)"
    id="seller_name"
    label="Vendeur"
    name="seller_name"
    v-model="selectedSellerName"
    :options="sellerOptions"
  />
  <BreakdownTable v-if="breakdown?.general" :breakdown="breakdown.general" />
  <div v-if="isPresent(breakdown?.salesByFormula)" class="grid grid-cols-1 gap-5 sm:grid-cols-2 mb-4">
    <PieChart
      class="mt-5"
      title="Répartition de la rémunération par formule"
      :data="transformHashKeys(breakdown.salesByFormula)"
    />
    <PieChart
      class="mt-5"
      title="Répartition de la rémunération par durée"
      :data="transformHashKeys(breakdown.salesByTermLength)"
    />
  </div>
</template>

<script>
import BreakdownTable from "@/components/invoice/breakdown/BreakdownTable.vue";
import AdminService from "@/api/services/admin";
import Selector from "@/components/utils/Selector.vue";
import { PieChart } from "@estaly/ui";

import { toCamelCase, transformHashKeys } from "@estaly/ui/src/utils/data_formatter";
import { isPresent } from "@/utils/validation";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";

export default {
  components: { BreakdownTable, Selector, PieChart },

  props: {
    invoice: {
      type: Object,
      required: true,
    },
    recurringBreakdown: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      filters: {
        salesChannel: [],
        sellerName: [],
      },
      breakdown: null,
      selectedSellerName: null,
    };
  },

  mounted() {
    this.fetchData();
  },

  computed: {
    params() {
      return {
        recurring_breakdown: this.recurringBreakdown,
        seller_name: this.selectedSellerName,
      };
    },

    computedTitle() {
      return this.recurringBreakdown ? "Rémunération récurrente" : "Rémunération générée par les nouvelles ventes";
    },

    sellerOptions() {
      const options = [{ value: null, label: "Tous les vendeurs" }];
      return options.concat(
        this.filters.sellerName.map((seller) => ({
          value: seller,
          label: seller,
        })),
      );
    },

    totalCommission() {
      return this.breakdown?.general?.reduce((sum, item) => sum + parseFloat(item.totalCommission || 0), 0) || 0;
    },
  },

  methods: {
    isPresent,
    formatPrice,
    transformHashKeys,
    async fetchData() {
      const response = await AdminService.getInvoiceBreakdown(this.invoice.id, this.params);
      if (response?.success) {
        const data = toCamelCase(response.data);
        this.filters = data.filters;
        this.breakdown = data.breakdown;
      }
    },
  },

  watch: {
    async selectedSellerName() {
      await this.fetchData();
    },
  },
};
</script>
