<template>
  <Modal :open="open" @close="close">
    <div>
      <h1 class="text-center font-extrabold text-xl">Résilier le contrat</h1>
      <termination-form @updateSelectedReason="updateSelectedReason" @updateFeedbackMessage="updateFeedbackMessage" />

      <div class="mt-4 flex justify-center">
        <GenericButton @onClick="handlePlanSaleCancel">Résilier</GenericButton>
      </div>
    </div>
  </Modal>
</template>

<script>
import { Modal } from "@estaly/ui";
import GenericButton from "@/components/utils/GenericButton.vue";
import TerminationForm from "@/views/admin/plansale/details/TerminationForm.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Modal,
    GenericButton,
    TerminationForm,
  },

  data() {
    return {
      selectedReason: "",
      feedbackMessage: "",
    };
  },

  props: ["open"],

  methods: {
    ...mapActions("notifications", ["notify"]),

    handlePlanSaleCancel() {
      this.$emit("cancelPlanSale", this.selectedReason, this.feedbackMessage);
    },

    close() {
      this.$emit("close");
    },

    updateSelectedReason(value) {
      this.selectedReason = value;
    },

    updateFeedbackMessage(value) {
      this.feedbackMessage = value;
    },
  },
};
</script>
