<template>
  <div v-if="organization">
    <IbanMissing v-if="isIbanMissing" />
    <div class="flex flex-row justify-between">
      <h1 class="text-2xl font-heading-1 tracking-tight text-gray-900">
        {{ $t("General informations") }}
      </h1>
    </div>
    <div>
      <div class="bg-white rounded-md my-4">
        <div class="grid grid-cols-1 sm:grid-cols-4 px-4 py-2 sm:px-6">
          <div class="flex justify-center items-center">
            <img :src="organization.logoUrl" :alt="organization.name" class="max-w-full h-auto" />
          </div>
          <div class="sm:col-span-3">
            <h3 class="p-8 text-3xl leading-6 text-gray-900 font-bold text-center">
              {{ organization.name }}
            </h3>
            <dl class="px-4 py-5 grid grid-cols-3 gap-x-4 gap-y-8">
              <div>
                <dt class="text-sm font-bold text-gray-500">{{ $t("Source") }}</dt>
                <dd class="mt-1 text-sm text-gray-900">{{ $t(`organization.source.${organization.source}`) }}</dd>
              </div>
              <div>
                <dt class="text-sm font-bold text-gray-500">{{ $t("Network type") }}</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ organization.networkType || "_" }}
                </dd>
              </div>
              <div>
                <dt class="text-sm font-bold text-gray-500">{{ $t("Number of stores") }}</dt>
                <dd class="mt-1 text-sm text-gray-900">{{ organization.numberOfStores }}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div class="bg-white shadow sm:rounded-lg mt-6" v-if="isOrganizationInvoiceLevel && isOrganizationManager">
        <div class="flex justify-between">
          <h3 class="px-4 py-4 text-lg leading-6 font-medium text-gray-900 font-bold">
            {{ $t("Invoicing") }}
          </h3>
          <PencilIcon class="mx-4 my-4 cursor-pointer w-6 h-6 text-primary-dark" @click="openBillingModal" />
        </div>
        <div class="px-4 py-5 grid grid-cols-3 gap-x-4 gap-y-8">
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Billing entity") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ organization.businessName }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Billing address") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ organization.address }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Iban") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ organization.iban || "_" }}
            </dd>
          </div>
        </div>
      </div>
      <BillingModal
        :openModal="isBillingModalOpen"
        :iban="organization.iban"
        @closeBillingModal="closeBillingModal"
        @updateIban="updateIban"
      />
    </div>
  </div>
</template>

<script>
import BillingModal from "@/views/admin/store/details/active/BillingModal.vue";
import IbanMissing from "@/components/IbanMissing.vue";
import { PencilIcon } from "@heroicons/vue/solid";
import OrganizationService from "@/api/services/organization";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    BillingModal,
    PencilIcon,
    IbanMissing,
  },

  data() {
    return {
      organization: null,
      isBillingModalOpen: false,
    };
  },

  computed: {
    ...mapState("auth", ["currentUser"]),
    ...mapGetters("auth", ["isOrganizationManager"]),

    isOrganizationInvoiceLevel() {
      return this.organization.invoiceLevel === "Organisation";
    },

    isIbanMissing() {
      return !this.organization.iban && this.isOrganizationInvoiceLevel && this.isOrganizationManager;
    },
  },

  async created() {
    await this.retrieveOrganization();
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    openBillingModal() {
      this.isBillingModalOpen = true;
    },

    closeBillingModal() {
      this.isBillingModalOpen = false;
    },

    async retrieveOrganization() {
      try {
        let organizationId = this.currentUser.organization.id;
        let response = await OrganizationService.getOrganization(organizationId);
        this.organization = response.generalInformations;
      } catch (error) {
        console.error(error);
      }
    },

    async updateIban(iban) {
      try {
        await OrganizationService.updateOrganization(this.organization.id, { iban });
        await this.notify({
          category: "simple",
          type: "success",
          title: "L'Iban a bien été mis à jour",
        });
        await this.retrieveOrganization();
        this.closeBillingModal();
      } catch (error) {
        const errorMessage = error.response.data.error || error.message;
        await this.notify({
          category: "simple",
          type: "error",
          text: errorMessage,
        });
      }
    },
  },
};
</script>
