<template>
  <CommonAnalyticsTable
    :stats="this.stats"
    :total="this.total"
    :currentMonth="currentMonth"
    @sortChanged="sortChanged"
  />
</template>
<script>
import CommonAnalyticsTable from "@/components/analytics/retail/utils/CommonAnalyticsTable.vue";

export default {
  components: {
    CommonAnalyticsTable,
  },

  props: ["stats", "total", "currentMonth"],

  methods: {
    sortChanged(event) {
      this.$emit("sortChanged", event);
    },
  },
};
</script>
