import { isPresent } from "@/utils/validation";

export function isAdmin(user) {
  return isPresent(user) && isPresent(user.permissions) && user.permissions.includes("admin");
}

export function isSeller(user) {
  return isPresent(user) && user.storeRole === "seller";
}

export function isResponsible(user) {
  return isPresent(user) && user.storeRole === "responsible";
}
