<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="text-4xl font-heading-1 pl-2 tracking-tight text-gray-900 my-4">
        {{ $t("Users") }}
      </h1>
      <div>
        <GenericButton @onClick="addUserModalOpen = true">
          {{ $t("Add a user") }}
        </GenericButton>
        <Modal :open="addUserModalOpen" @close="addUserModalOpen = false">
          <UserForm @success="userCreated" />
        </Modal>
      </div>
    </div>
    <SearchBar
      class="my-4"
      :current-page="currentPage"
      :placeholder="'Recherche par nom/email'"
      :initial-keywords="keywords"
      :available-filters="[]"
      :selected-filters="[]"
      @updateKeywordSearch="updateKeywordSearch"
    />
    <div class="my-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead class="bg-gray-50">
              <tr class="hidden md:table-row">
                <th
                  scope="col"
                  class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                >
                  {{ $t("Last Name") }}
                </th>
                <th
                  scope="col"
                  class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                >
                  {{ $t("Email") }}
                </th>
                <th
                  scope="col"
                  class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                >
                  {{ $t("Selling points") }}
                </th>
                <th
                  scope="col"
                  class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                >
                  {{ $t("Status") }}
                </th>
                <th
                  scope="col"
                  class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                >
                  {{ $t("Role") }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="profile in profiles" :key="profileKey(profile)" :class="['cursor-pointer hover:bg-gray-100']">
                <td class="whitespace-nowrap py-4 pl-4 pr-2 text-xs font-medium text-gray-900 sm:pl-4">
                  {{ profile.name }}
                </td>
                <td class="whitespace-nowrap py-4 pl-4 pr-2 text-xs font-medium text-gray-900 sm:pl-4">
                  {{ profile.email }}
                </td>
                <td class="whitespace-nowrap py-4 pl-4 pr-2 text-xs font-medium text-gray-900 sm:pl-4">
                  {{ profile.salesChannels.slice(0, 2).join(", ") }}
                  <template v-if="profile.salesChannels.length > 2">
                    <i @click="showProfileStores = profileKey(profile)">
                      , +{{ profile.salesChannels.length - 2 }} autres
                    </i>
                  </template>
                </td>
                <td class="whitespace-nowrap py-4 pl-4 pr-2 text-xs font-medium text-gray-900 sm:pl-4">
                  <FieldSpan
                    :style-to-add="['text-white', profile.activated ? 'bg-green-400' : 'bg-red-400']"
                    :value="profile.activated ? 'Actif' : 'Inactif'"
                  />
                </td>
                <td class="whitespace-nowrap py-4 pl-4 pr-2 text-xs font-medium text-gray-900 sm:pl-4">
                  <FieldSpan
                    :style-to-add="'bg-blue-400 text-white'"
                    :value="profile.role"
                    :translation-prefix="'roles'"
                  />
                </td>
                <td>
                  <TrashIcon class="h-5 cursor-pointer" @click="deleteProfile(profile)" />
                </td>
              </tr>
            </tbody>
          </table>
          <Modal :open="showProfileStores !== null" @close="showProfileStores = null">
            <ul>
              <li v-for="channel in profileStores" :key="channel">
                {{ channel }}
              </li>
            </ul>
          </Modal>
        </div>
      </div>
    </div>
    <Pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      :totalCount="totalCount"
      @changePage="handleChangePage"
    />
  </div>
</template>
<script>
import { TrashIcon } from "@heroicons/vue/outline";
import FieldSpan from "@/components/utils/FieldSpan.vue";
import { Modal, Pagination } from "@estaly/ui";
import { mapActions, mapState } from "vuex";
import UserService from "@/api/services/user";
import SearchBar from "@/components/menu/SearchBar.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import UserForm from "@/components/UserForm.vue";
import { getQueryParams, setQueryParams } from "@/utils/query_params";

export default {
  components: {
    Pagination,
    TrashIcon,
    SearchBar,
    FieldSpan,
    GenericButton,
    Modal,
    UserForm,
  },

  async mounted() {
    this.initQueryParams();
    await this.retrieveUsers(this.currentPage, this.keywords);
  },

  data() {
    return {
      profiles: [],
      currentPage: 1,
      keywords: null,
      totalCount: null,
      totalPages: null,
      showProfileStores: null,
      addUserModalOpen: false,
    };
  },

  computed: {
    ...mapState("store", ["isLoading"]),

    profileStores() {
      if (this.showProfileStores) {
        return this.profiles.find((profile) => this.profileKey(profile) === this.showProfileStores).salesChannels;
      }
      return [];
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async retrieveUsers(page, keywords) {
      setQueryParams({ page: page, keywords: keywords });
      const response = await UserService.getProfiles(page, keywords);
      this.profiles = response.data.profiles;
      this.totalCount = response.data.page.totalCount;
      this.totalPages = response.data.page.totalPages;
    },

    initQueryParams() {
      const queryParams = getQueryParams();
      this.currentPage = queryParams.page || 1;
      this.keywords = queryParams.sales_channel;
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.retrieveUsers(page, this.keywords);
    },

    updateKeywordSearch(keywords) {
      this.keywords = keywords;
      this.handleChangePage(1);
    },

    profileKey(profile) {
      // id may not be unique because profiles merge OrganizationManagers and StoreManagers
      return profile.id + profile.role;
    },

    async userCreated() {
      this.addUserModalOpen = false;
      await this.reloadProfiles();
    },

    async deleteProfile(profile) {
      if (!confirm(`Supprimer ${profile.name} ?`)) return;

      const response = await UserService.deleteProfile(profile.id, profile.type);
      if (!response?.success) return;

      await this.reloadProfiles();
      await this.notify({
        category: "simple",
        type: "success",
        title: "Utilisateur supprimé",
      });
    },

    async reloadProfiles() {
      this.keywords = null;
      this.currentPage = 1;
      await this.retrieveUsers(this.currentPage, this.keywords);
    },
  },
};
</script>
