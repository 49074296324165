<template>
  <div :class="{ hidden: !isActive }">
    <div
      tabindex="0"
      class="vld-overlay is-active absolute top-0 left-0 w-full h-full"
      aria-busy="false"
      aria-label="Loading"
    >
      <div class="vld-background" style="opacity: 0.7; backdrop-filter: blur(2px)"></div>
      <div class="vld-icon">
        <svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" width="64" height="64" stroke="#4f46e5">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
              <circle stroke-opacity=".25" cx="18" cy="18" r="18"></circle>
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="0.8s"
                  repeatCount="indefinite"
                ></animateTransform>
              </path>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { rollbar } from "@/rollbar";
import { mapMutations, mapState } from "vuex";

export default {
  components: {},

  data: () => {
    return {
      active: true,
      loadingTimeout: 10000, // milliseconds
      loadingTimeoutId: null,
    };
  },

  computed: {
    ...mapState("store", ["isLoading"]),

    isActive() {
      return this.active && this.isLoading;
    },
  },

  methods: {
    ...mapMutations("store", ["setIsLoading"]),
  },

  watch: {
    isLoading(newValue) {
      // Prevent infinite loading...
      // Ensure the loader active for at most for X seconds
      window.clearTimeout(this.loadingTimeoutId);
      this.active = newValue;
      if (!this.active) return;

      this.loadingTimeoutId = window.setTimeout(() => {
        this.active = false;
        this.setIsLoading(false);
        const error = `Loading timeout after ${this.loadingTimeout}ms on ${this.$route.fullPath}`;
        console.error(error);
        rollbar.error(error);
      }, this.loadingTimeout);
    },
  },
};
</script>
<style scoped>
.vld-shown {
  overflow: hidden;
}

.vld-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  z-index: 9999;
}

.vld-overlay.is-active {
  display: flex;
}

.vld-overlay.is-full-page {
  z-index: 9999;
  position: fixed;
}

.vld-overlay .vld-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
}

.vld-overlay .vld-icon,
.vld-parent {
  position: relative;
}
</style>
