<template>
  <Modal :size="'big'" :open="open" @close="close">
    <p class="text-center pb-7 font-bold text-xl">Détail des coûts de la facture</p>
    <table class="min-w-full bg-white">
      <thead>
        <tr class="w-full bg-gray-50 text-sm">
          <th class="px-4 py-2 text-center">Type</th>
          <th class="px-4 py-2 text-center">Description</th>
          <th class="px-4 py-2 text-center">Prix unitaire HT</th>
          <th class="px-4 py-2 text-center">Prix unitaire TTC</th>
          <th class="px-4 py-2 text-center">Quantité</th>
          <th class="px-4 py-2 text-center">Total</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="costItem in invoice.costs" :key="costItem.id" class="hover:bg-gray-200 text-sm">
          <td class="px-4 py-2 text-center">
            {{ $t(`claim.cost_type.${costItem.type}`) }}
          </td>
          <td class="px-4 py-2 text-center">
            {{ costItem.description }}
          </td>
          <td class="px-4 py-2 text-center">
            {{ costItem.unitPriceBeforeTax }}
          </td>
          <td class="px-4 py-2 text-center">{{ costItem.unitPrice }}</td>
          <td class="px-4 py-2 text-center">{{ costItem.quantity }}</td>
          <td class="px-4 py-2 text-center font-bold">{{ costItem.total }}</td>
        </tr>
      </tbody>
    </table>
    <div class="mt-6 flex items-center justify-end text-sm font-bold pr-6">
      <span>Total TTC :</span>
      <span class="ml-3">{{ invoice.price }}</span>
    </div>
  </Modal>
</template>

<script>
import { Modal } from "@estaly/ui";
export default {
  components: { Modal },
  props: ["invoice", "open"],
  close: function () {
    this.$emit("close");
  },
};
</script>
