<template>
  <div class="font-extralight flex flex-col justify-center">
    <p class="font-heading-1 uppercase text-4xl">Challenge exceptionnel : chaque vente compte !</p>
    <p class="text-lg my-2 text-gray-600">
      Le challenge s'étend du {{ challenge.startDate }} au {{ challenge.endDate }} - Relevez le défi ! 🎯
    </p>
    <div v-if="isAdmin" class="p-6 bg-yellow-50 rounded shadow-md my-2">
      <p class="text-lg font-semibold text-gray-800">Explication du système de récompense 💡</p>
      <p class="text-sm text-gray-600 mt-2">
        Chaque vente réalisée par les vendeurs rapporte des
        <span class="font-black text-black">points.</span>
        Une fois convertis,
        <span class="font-black text-black">{{ challenge.pointsPerEuro }} points</span>
        correspondent à
        <span class="font-black text-black">1 €.</span>
      </p>
      <p class="text-sm text-gray-600 mt-2">
        La récompense totale est ensuite répartie comme suit :
        <span class="font-black text-black">70 %</span>
        pour les vendeurs et
        <span class="font-black text-black">30 %</span>
        pour la direction et le management.
      </p>
    </div>

    <div v-if="isAdmin && isPresent(storePerformance)" class="shadow-lg bg-gray-50 p-6 my-6">
      <div class="flex justify-between">
        <div class="flex space-x-4">
          <div>
            <p class="text-2xl font-heading-1">{{ storePerformance.salesChannel }}</p>
          </div>
        </div>

        <div class="flex space-x-6">
          <div class="text-center">
            <p class="text-4xl font-heading-1 text-gray-400">{{ storePerformance.storeScore }}</p>
            <p class="text-sm text-gray-500">Points</p>
          </div>

          <div class="text-center">
            <p class="text-4xl font-heading-1">
              {{ formatPrice(storePerformance.storeRewardAmount) }}
            </p>
            <p class="text-sm text-gray-500">Récompense totale</p>
          </div>
        </div>
      </div>

      <div class="mt-6 grid grid-cols-2 gap-4">
        <div class="p-4 bg-white rounded shadow-md text-center">
          <p class="text-2xl font-semibold">
            {{ formatPrice(storePerformance.sellerRewardAmount) }}
          </p>
          <p class="text-sm text-gray-500">Pour les vendeurs</p>
        </div>
        <div class="p-4 bg-white rounded shadow-md text-center">
          <p class="text-2xl font-semibold">
            {{ formatPrice(storePerformance.managersRewardAmount) }}
          </p>
          <p class="text-sm text-gray-500">Pour la direction et le management</p>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 sm:space-x-6">
      <div class="grid grid-cols-1 space-y-4">
        <div>
          <div class="mb-4 text-xl font-heading-1">🏠 Classement magasin</div>
          <div
            v-if="isPresent(sortedSellers) && sortedSellers[0].score > 0"
            class="shadow-lg bg-gray-50 relative flex items-end justify-center p-6 space-x-6"
          >
            <div v-if="sortedSellers[2]" class="flex flex-col items-center">
              <div class="text-2xl translate-y-0">
                <p class="text-base">{{ sortedSellers[2].name }} 🥉</p>
              </div>
              <div
                class="bg-purple-300 text-white rounded-lg shadow-lg flex flex-col items-center justify-center p-4 h-16 w-32"
              >
                <p class="text-md text-center mb-2">{{ sortedSellers[2].score }} points</p>
                <p class="text-md text-center">{{ formatPrice(sortedSellers[2].rewardAmount) }}</p>
              </div>
            </div>

            <div v-if="sortedSellers[0]" class="flex flex-col items-center">
              <div class="text-2xl -translate-y-4">
                <p class="text-base">{{ sortedSellers[0].name }} 🥇</p>
              </div>
              <div
                class="bg-purple-900 text-white rounded-lg shadow-lg flex flex-col items-center justify-center p-4 h-32 w-32"
              >
                <p class="text-md text-center mb-2">{{ sortedSellers[0].score }} points</p>
                <p class="text-md text-center">{{ formatPrice(sortedSellers[0].rewardAmount) }}</p>
              </div>
            </div>

            <div v-if="sortedSellers[1]" class="flex flex-col items-center">
              <div class="text-2xl -translate-y-2">
                <p class="text-base">{{ sortedSellers[1].name }} 🥈</p>
              </div>
              <div
                class="bg-purple-600 text-white rounded-lg shadow-lg flex flex-col items-center justify-center p-4 h-24 w-32"
              >
                <p class="text-md text-center mb-2">{{ sortedSellers[1].score }} points</p>
                <p class="text-md text-center">{{ formatPrice(sortedSellers[1].rewardAmount) }}</p>
              </div>
            </div>
          </div>
          <div v-else class="relative flex items-end justify-center bg-purple-50 p-6 rounded-lg space-x-4">
            Pas encore de ventes
          </div>
        </div>
        <div>
          <div class="mb-4 text-xl font-heading-1">🌍 Classement réseau</div>
          <div
            v-if="isPresent(organizationSellers) && organizationSellers[0].score > 0"
            class="shadow-lg bg-gray-50 relative flex items-end justify-center p-6 space-x-6"
          >
            <div v-if="organizationSellers[2]" class="flex flex-col items-center">
              <div class="text-2xl translate-y-0">
                <p class="text-base">{{ organizationSellers[2].name }} 🥉</p>
                <p class="text-xs mb-2">{{ organizationSellers[2].storeSalesChannel }}</p>
              </div>
              <div
                class="bg-purple-300 text-white rounded-lg shadow-lg flex flex-col items-center justify-center p-4 h-16 w-32"
              >
                <p class="text-md text-center mb-2">{{ organizationSellers[2].score }} points</p>
                <p class="text-md text-center">{{ formatPrice(organizationSellers[2].rewardAmount) }}</p>
              </div>
            </div>

            <div v-if="organizationSellers[0]" class="flex flex-col items-center">
              <div class="text-2xl -translate-y-4">
                <p class="text-base">{{ organizationSellers[0].name }} 🥇</p>
                <p class="text-xs mb-2">{{ organizationSellers[0].storeSalesChannel }}</p>
              </div>
              <div
                class="bg-purple-900 text-white rounded-lg shadow-lg flex flex-col items-center justify-center p-4 h-32 w-32"
              >
                <p class="text-md text-center mb-2">{{ organizationSellers[0].score }} points</p>
                <p class="text-md text-center">{{ formatPrice(organizationSellers[0].rewardAmount) }}</p>
              </div>
            </div>

            <div v-if="organizationSellers[1]" class="flex flex-col items-center">
              <div class="text-2xl -translate-y-2">
                <p class="text-base">{{ organizationSellers[1].name }} 🥈</p>
                <p class="text-xs mb-2">{{ organizationSellers[1].storeSalesChannel }}</p>
              </div>
              <div
                class="bg-purple-600 text-white rounded-lg shadow-lg flex flex-col items-center justify-center p-4 h-24 w-32"
              >
                <p class="font-bold text-md text-center mb-2">{{ organizationSellers[1].score }} points</p>
                <p class="font-bold text-md text-center">{{ formatPrice(organizationSellers[1].rewardAmount) }}</p>
              </div>
            </div>
          </div>

          <div v-else class="relative flex items-end justify-center bg-purple-50 p-6 rounded-lg space-x-4">
            Pas encore de ventes
          </div>
        </div>
      </div>
      <div v-if="isPresent(sortedSellers)">
        <div class="mb-4 text-xl font-heading-1">📊 Détail du classement magasin</div>
        <div class="overflow-x-auto overflow-y-auto shadow-lg bg-gray-50 relative" style="max-height: 59vh">
          <table class="table-auto w-full text-left border-collapse border">
            <tbody>
              <tr
                v-for="(seller, index) in sortedSellers"
                :key="seller.id"
                :class="index % 2 === 0 ? 'bg-gray-50' : ''"
              >
                <td class="px-4 py-2 w-10">{{ index + 1 }}</td>
                <td class="px-4 py-2">{{ seller.name }}</td>
                <td class="px-4 py-2 text-right">{{ seller.score }} points</td>
                <td class="px-4 py-2 text-right">{{ formatPrice(seller.rewardAmount) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div>
      <div class="my-4 text-xl font-heading-1">📋 Détail des points par offre</div>
      <OffersTable :matchedContracts="matchedContracts" />
    </div>
  </div>
</template>

<script>
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import { isPresent } from "@/utils/validation";
import { mapGetters } from "vuex";

import OffersTable from "@/components/challenge/OffersTable.vue";

export default {
  props: ["challenge", "sellers", "organizationSellers", "matchedContracts", "selectedStore", "storePerformance"],

  components: { OffersTable },

  computed: {
    ...mapGetters("auth", ["isAdmin"]),
    sortedSellers() {
      return this.sellers ? [...this.sellers].sort((a, b) => b.points - a.points) : [];
    },
    groupedContracts() {
      if (!this.matchedContracts) return [];
      const grouped = this.matchedContracts.reduce((acc, contract) => {
        const category = contract.category;
        if (!acc[category]) acc[category] = { category, contracts: [] };
        acc[category].contracts.push(contract);
        return acc;
      }, {});
      return Object.values(grouped);
    },
  },

  methods: {
    formatPrice,
    isPresent,
    formatTermLength(contract) {
      if (contract.monthlyBilling) return "Mensuel";
      else if (contract.termLength == 1) return "1 an";
      return contract.termLength + " ans";
    },
  },
};
</script>
