<template>
  <div class="px-2 my-4">
    <h1 class="text-4xl font-heading-1 tracking-tight text-gray-900">
      {{ $t("Challenges") }}
    </h1>
  </div>

  <Tabs :tabs="tabs" @tab-selected="handleTabChange" />

  <div v-if="selectedTab === 'current'">
    <div v-if="activeChallenge">
      <ChallengePerformances :challenge="challenge" :performance="performance" />
    </div>
    <div v-else class="p-6 bg-white">
      <p class="font-heading-1 uppercase text-2xl">Pas de challenge en cours.</p>
    </div>
  </div>

  <div v-else-if="selectedTab === 'all'">
    <ChallengeList :challenges="challenges" :storeId="this.selectedStoreId" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import StoreService from "@/api/services/store";
import { Tabs } from "@estaly/ui";
import ChallengePerformances from "@/components/challenge/ChallengePerformances.vue";
import ChallengeList from "@/components/challenge/ChallengeList.vue";

import { toCamelCase } from "@estaly/ui/src/utils/data_formatter";

export default {
  components: { Tabs, ChallengePerformances, ChallengeList },

  data() {
    return {
      challenge: {},
      performance: {},
      challenges: [],
      selectedTab: "current",
      tabs: [
        { name: "current", displayName: "En cours", current: true },
        { name: "all", displayName: "Historique", current: false },
      ],
    };
  },

  computed: {
    ...mapState("store", ["selectedStoreId"]),
    activeChallenge() {
      return this.challenges.find((challenge) => challenge.active);
    },
  },

  async mounted() {
    if (this.selectedStoreId) {
      await this.retrieveChallenges();
    }
  },

  methods: {
    ...mapActions("store", ["withLoader"]),

    async retrieveChallenges() {
      await this.withLoader(async () => {
        const response = await StoreService.getChallenges(this.selectedStoreId);
        if (response?.success) {
          const data = toCamelCase(response.data);
          this.challenges = data.challenges;
        }
      });
    },

    handleTabChange(selectedTab) {
      this.selectedTab = selectedTab.name;
    },

    handleTabAndDataChange() {
      if (this.selectedTab === "current" && this.activeChallenge) {
        this.retrieveChallengePerformances(this.selectedStoreId, this.activeChallenge.id);
      }
    },

    async retrieveChallengePerformances(storeId, challengeId) {
      const response = await StoreService.getChallengesAndSellersPerformance(storeId, challengeId);
      if (response?.success) {
        const data = toCamelCase(response.data);
        this.challenge = data.challenge;
        this.performance = data.performance;
      }
    },
  },

  watch: {
    selectedTab: "handleTabAndDataChange",
    activeChallenge: "handleTabAndDataChange",
  },
};
</script>
