<template>
  <div class="font-extralight flex flex-col justify-center space-y-6">
    <p class="font-heading-1 uppercase text-4xl">Challenge exceptionnel : atteignez un palier !</p>
    <p class="text-xl">
      Le challenge s'étend du {{ challenge.startDate }} au {{ challenge.endDate }} - Relevez le défi ! 🎯
    </p>

    <div>
      <p class="font-bold text-xl mb-4">📊 Suivi des performances</p>
      <div v-for="seller in sellers" :key="seller">
        <div>
          <p class="text-xs font-bold">{{ seller.name }}</p>
        </div>
        <div class="w-full mt-4 flex flex-col pb-6 justify-self-center pr-10">
          <div class="relative h-2 bg-gray-200 rounded overflow-visible">
            <div
              class="absolute left-0 top-0 h-2 bg-primary-dark rounded"
              style="top: 50%; transform: translateY(-50%)"
              :style="{ width: progressWidth(seller) + '%' }"
            ></div>

            <div
              v-for="target in challenge.salesTargets"
              :key="target.target"
              class="bg-primary rounded-full text-white absolute flex items-center justify-center h-6 w-6"
              :style="{
                left: `calc(${targetPosition(target.target)}% - 0.5rem)`,
                top: '50%',
                transform: 'translateY(-50%)',
              }"
            >
              <div
                class="text-xs"
                :class="{
                  'text-yellow-400 font-bold': target.target === nextTarget(seller).target,
                }"
              >
                {{ target.target }}
              </div>
            </div>

            <div
              v-for="target in challenge.salesTargets"
              :key="target.reward"
              class="rounded-full text-primary-dark font-bold absolute flex items-center justify-center h-6 w-6"
              :style="{
                left: `calc(${targetPosition(target.target)}% - 0.3rem + 20px)`, // Décalage à droite
                top: 'calc(50% + 20px)', // Décalage vers le bas
                transform: 'translate(-50%, -50%)',
              }"
            >
              <div
                class="text-xs"
                :class="{
                  'text-yellow-400 font-extrabold ml-8': target.target === nextTarget(seller).target,
                }"
              >
                {{ formatPrice(target.reward) }}
              </div>
            </div>

            <div
              class="bg-white rounded-full text-primary-dark absolute flex items-center justify-center h-6 w-6 shadow-md"
              :style="{
                left: `calc(${progressWidth(seller)}% - 0.5rem)`,
                bottom: '120%',
                transform: 'translateY(0.5rem)',
              }"
            >
              <div class="text-xs">{{ seller.score }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-10">
      <p class="font-bold text-xl mb-4">📋 Tableau des performances</p>
      <table class="table-auto w-full border-collapse border border-gray-300 text-left text-sm">
        <thead class="bg-gray-100">
          <tr>
            <th class="border border-gray-300 px-4 py-2">Vendeur</th>
            <th class="border border-gray-300 px-4 py-2">Nombre de ventes</th>
            <th class="border border-gray-300 px-4 py-2">Gain</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="seller in sellers" :key="seller.name" class="odd:bg-white even:bg-gray-50">
            <td class="border border-gray-300 px-4 py-2">{{ seller.name }}</td>
            <td class="border border-gray-300 px-4 py-2">{{ seller.score }}</td>
            <td class="border border-gray-300 px-4 py-2">{{ formatPrice(seller.rewardAmount) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <p>
      Vos récompenses seront versées sous forme de cartes 🎁 (multi-enseignes, utilisable en ligne et en magasin) à
      débloquer à la fin du challenge.
    </p>
    <p>Alors, visez le palier le plus haut possible 🎯 et récoltez votre récompense 🏆!</p>
    <p>Bonne chance 😉</p>
  </div>
</template>

<script>
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";

export default {
  props: ["challenge", "sellers"],

  methods: {
    formatPrice,

    targetPosition(target) {
      return (target / this.maxTargetChallenge()) * 100;
    },

    maxTarget() {
      return Math.max(...this.challenge.salesTargets.map((item) => item.target));
    },

    nextTarget(seller) {
      const salesTargets = this.challenge?.salesTargets || [];
      const score = seller?.score || 0;

      return salesTargets.find((t) => t.target > score) || salesTargets[salesTargets.length - 1];
    },
    maxTargetChallenge() {
      const salesTargets = this.challenge?.salesTargets || [];

      return salesTargets[salesTargets.length - 1]?.target;
    },
    progressWidth(seller) {
      return this.maxTargetChallenge ? (seller?.score / this.maxTargetChallenge()) * 100 : 100;
    },
  },
};
</script>
