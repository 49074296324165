<template>
  <Table :columns="columns" :data="rows" v-if="rows.length > 0" class="mt-2">
    <template #category="{ row, colspan }">
      <td class="full-row bg-purple-200 px-4 py-2 text-xl font-heading-1" :colspan="colspan">
        {{ row.category }}
      </td>
    </template>
    <template #duration="{ row, colspan }">
      <td class="full-row bg-purple-100 px-4 py-2 font-semibold" :colspan="colspan">
        {{ row.duration }}
      </td>
    </template>
  </Table>
</template>

<script>
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import { formatPriceBand } from "@estaly/ui/src/utils/price_band_formatter";
import { formatTermLength } from "@estaly/ui/src/utils/term_length_formatter";
import { mapGetters } from "vuex";

import { Table } from "@estaly/ui";

export default {
  components: { Table },
  props: {
    matchedContracts: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["isAdmin"]),
    columns() {
      let baseColumns = [
        { field: "priceBand", label: "Prix du produit" },
        { field: "price", label: "Prix de l’assurance", numeric: true },
        { field: "points", label: "Points", numeric: true },
      ];
      if (this.isAdmin) {
        baseColumns.push({ field: "rewardAmountForStore", label: "Gain total", numeric: true });
      }
      baseColumns.push({ field: "rewardAmountForSeller", label: "Gain vendeur", numeric: true });
      return baseColumns;
    },
    rows() {
      return this.categories.map((category) => this.categoryRows(category)).flat();
    },
    categories() {
      return [...new Set(this.matchedContracts.map((contract) => contract.category))];
    },
    termLengths() {
      const terms = this.matchedContracts.filter((contract) => !contract.monthlyBilling);
      return [...new Set(terms.map((contract) => contract.termLength))].sort((a, b) => a - b);
    },
  },
  methods: {
    categoryRows(category) {
      const rows = [{ fullRow: true, slot: "category", category }];
      const data = this.matchedContracts.filter((contract) => contract.category === category);

      const monthlyRows = data
        .filter((contract) => contract.monthlyBilling)
        .map(this.row)
        .sort(this.sortRows);
      if (monthlyRows.length > 0) {
        rows.push({ fullRow: true, slot: "duration", duration: this.$t("termLength.monthly") });
        rows.push(...monthlyRows);
      }

      this.termLengths.forEach((termLength) => {
        const yearlyRows = data
          .filter((contract) => !contract.monthlyBilling && contract.termLength === termLength)
          .map(this.row)
          .sort(this.sortRows);
        if (yearlyRows.length > 0) {
          rows.push({ fullRow: true, slot: "duration", duration: this.$t(`termLength.${termLength}_year`) });
          rows.push(...yearlyRows);
        }
      });

      return rows;
    },
    row(contract) {
      return {
        id: contract.id,
        category: contract.category,
        priceBand: formatPriceBand(contract),
        duration: formatTermLength(contract),
        price: formatPrice(contract.price),
        points: contract.points,
        rewardAmountForStore: formatPrice(contract.rewardAmountForStore),
        rewardAmountForSeller: formatPrice(contract.rewardAmountForSeller),
      };
    },
    sortRows(a, b) {
      if (a.termLength === b.termLength) return a.priceBandMin - b.priceBandMin;
      return a.termLength - b.termLength;
    },
  },
};
</script>
