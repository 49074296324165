<template>
  <Alert type="warning">
    <template #title>Un ou plusieurs IBANs sont manquants.</template>
    Veuillez renseigner les informations manquantes afin de vous assurer de percevoir vos rémunérations.
  </Alert>
</template>

<script>
import { Alert } from "@estaly/ui";

export default {
  components: {
    Alert,
  },
};
</script>
