<template>
  <GeneralAnalytics
    :analytics="analytics"
    :available-stores="availableStores"
    @filtersUpdated="analyticsFiltersUpdated"
    @viewSellersRanking="openSellersRankingModal = true"
  />

  <SellersRankingModal
    :open="openSellersRankingModal"
    :data="sellersRankingData"
    :total-count="sellersRankingTotalCount"
    :total-pages="sellersRankingTotalPages"
    :current-page="sellersRankingCurrentPage"
    @closeModal="openSellersRankingModal = false"
    @changePage="(newPage) => (sellersRankingCurrentPage = newPage)"
    @refreshData="getSellersRanking"
  />
</template>

<script>
import { GeneralAnalytics, SellersRankingModal } from "@estaly/ui";
import { mapActions } from "vuex";
import AnalyticsService from "@/api/services/analytics";
import SellerService from "@/api/services/seller";
import { toCamelCase } from "@estaly/bo/src/utils/utils";

export default {
  components: {
    SellersRankingModal,
    GeneralAnalytics,
  },

  data() {
    return {
      analytics: {},
      availableStores: [],
      currentFilters: {},
      openSellersRankingModal: false,
      sellersRankingData: [],
      sellersRankingTotalCount: null,
      sellersRankingTotalPages: null,
      sellersRankingCurrentPage: null,
    };
  },

  watch: {
    openSellersRankingModal(newValue) {
      if (newValue) {
        this.sellersRankingCurrentPage = 1;
      }
    },
    sellersRankingCurrentPage() {
      this.getSellersRanking();
    },
  },

  methods: {
    ...mapActions("store", ["withLoader"]),

    analyticsFiltersUpdated(filters) {
      this.currentFilters = filters;
      this.withLoader(() => this.fetchAnalytics(filters));
    },

    async fetchAnalytics(apiParams) {
      const response = await AnalyticsService.getGeneralAnalytics(apiParams);
      if (!response?.success) return;

      const formattedResponse = toCamelCase(response.data);

      this.analytics = formattedResponse.analytics;
      this.availableStores = formattedResponse.availableStores;
    },

    async getSellersRanking() {
      const params = {
        page: this.sellersRankingCurrentPage,
        startDate: this.currentFilters ? this.currentFilters.startDate : null,
        endDate: this.currentFilters ? this.currentFilters.endDate : null,
      };

      const response = await SellerService.getSellersRanking(params);

      if (response?.success) {
        const formattedResponse = toCamelCase(response.data);
        this.sellersRankingData = formattedResponse.sellers;
        this.sellersRankingTotalCount = formattedResponse.page.totalCount;
        this.sellersRankingTotalPages = formattedResponse.page.totalPages;
      }
    },
  },
};
</script>
