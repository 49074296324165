<template>
  <div class="bg-white shadow sm:rounded-t-md py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
    <img class="h-12 w-auto" src="@/assets/large_logo_estaly_black.png" alt="Estaly logo" />
    <div class="flex gap-x-6 items-center">
      <button class="ml-4 text-xs cursor-pointer hover:underline" @click="redirectToStore">Se connecter</button>
      <div><GenericButton @onClick="redirectToJoinEstalyPage">Rejoindre l'aventure</GenericButton></div>
    </div>
  </div>
  <div
    class="mt-8 bg-white shadow sm:rounded-t-md py-6 px-4 sm:px-6 lg:px-8 gap-x-6 flex flex-col sm:flex-row justify-between max-w-screen mx-auto"
  >
    <div class="pt-2">
      <p class="block text-sm leading-6 text-gray-900 sm:pt-1.5 font-semibold">Sélectionner une période</p>
      <MonthPicker
        class="text-sm w-full sm:w-80 rounded-md"
        :placeholder="$t('Select a time range')"
        v-model="dateRange"
        @update:modelValue="dateRangeUpdated"
      />
    </div>
    <div class="sm:w-60">
      <MultiSelect
        id="store-selector"
        label="Magasins"
        :options="storeOptions"
        v-model="selectedStores"
        placeholder="Choisissez un ou plusieurs magasins"
      />
    </div>
  </div>

  <div class="mt-8 grid grid-cols-1 gap-6 md:grid-cols-3">
    <div class="bg-white shadow rounded-md p-4">
      <h2 class="text-lg font-semibold mb-4">
        Marge générée
        <span v-if="isPresent(selectedRemunerations)" class="ml-4 text-md font-semibold text-green-500">
          {{ formatPrice(selectedTotalRemuneration) }}
        </span>
        <span v-else class="ml-4 text-md font-semibold text-green-500">
          {{ formatPrice(totalRemuneration) }}
        </span>
        <span class="text-sm" v-if="isPresent(selectedRemunerations)">
          (Compa. Réseau:
          <span :class="comparisonClass(totalAverageRemuneration, remunerationAverage)">
            {{ comparisonPercentage(totalAverageRemuneration, remunerationAverage) }}%
          </span>
          )
        </span>
      </h2>
      <div v-if="!analytics?.remunerations?.length" class="text-gray-500 text-center py-4">
        Aucune donnée disponible pour la période sélectionnée. Veuillez sélectionner une autre période.
      </div>
      <table v-else class="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th class="px-4 py-2 text-left text-sm font-medium text-gray-500">Position</th>
            <th class="px-4 py-2 text-left text-sm font-medium text-gray-500">Magasin</th>
            <th class="px-4 py-2 text-left text-sm font-medium text-gray-500">M</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in this.analytics?.remunerations"
            :key="index"
            class="hover:bg-gray-50"
            :class="{ 'bg-green-200 text-white': selectedStores.includes(item.entity_name) }"
          >
            <td class="px-4 py-2 text-sm text-gray-500">{{ index + 1 }}</td>
            <td class="px-4 py-2 text-sm text-gray-700">{{ item.entity_name }}</td>
            <td class="px-4 py-2 text-sm text-gray-700">{{ this.formatPrice(item.amount) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="bg-white shadow rounded-md p-4">
      <h2 class="text-lg font-semibold mb-4">
        Ventes réalisées
        <span v-if="isPresent(selectedSales)" class="ml-4 text-md font-semibold text-green-500">
          {{ selectedTotalSales }}
        </span>
        <span v-else class="ml-4 text-md font-semibold text-green-500">
          {{ totalSales }}
        </span>
        <span class="text-sm" v-if="isPresent(selectedSales)">
          (Compa. Réseau:
          <span :class="comparisonClass(totalAverageSales, salesAverage)">
            {{ comparisonPercentage(totalAverageSales, salesAverage) }}%
          </span>
          )
        </span>
      </h2>
      <div v-if="!analytics?.remunerations?.length" class="text-gray-500 text-center py-4">
        Aucune donnée disponible pour la période sélectionnée. Veuillez sélectionner une autre période.
      </div>
      <table v-else class="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th class="px-4 py-2 text-left text-sm font-medium text-gray-500">Position</th>
            <th class="px-4 py-2 text-left text-sm font-medium text-gray-500">Magasin</th>
            <th class="px-4 py-2 text-left text-sm font-medium text-gray-500">M</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in this.analytics?.sales"
            :key="index"
            class="hover:bg-gray-50"
            :class="{ 'bg-green-200 text-white': selectedStores.includes(item.entity_name) }"
          >
            <td class="px-4 py-2 text-sm text-gray-500">{{ index + 1 }}</td>
            <td class="px-4 py-2 text-sm text-gray-700">{{ item.entity_name }}</td>
            <td class="px-4 py-2 text-sm text-gray-700">{{ item.sales }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="bg-white shadow rounded-md p-4">
      <h2 class="text-lg font-semibold mb-4">
        CA des sinistres
        <span v-if="isPresent(selectedClaimsAmounts)" class="ml-4 text-md font-semibold text-green-500">
          {{ formatPrice(selectedTotalClaimsAmount) }}
        </span>
        <span v-else class="ml-4 text-md font-semibold text-green-500">
          {{ formatPrice(totalClaimsAmount) }}
        </span>
        <span class="text-sm" v-if="isPresent(selectedClaimsAmounts)">
          (Compa. Réseau:
          <span :class="comparisonClass(totalAverageClaimsAmount, claimsAmountAverage)">
            {{ comparisonPercentage(totalAverageClaimsAmount, claimsAmountAverage) }}%
          </span>
          )
        </span>
      </h2>
      <table class="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th class="px-4 py-2 text-left text-sm font-medium text-gray-500">Position</th>
            <th class="px-4 py-2 text-left text-sm font-medium text-gray-500">Magasin</th>
            <th class="px-4 py-2 text-left text-sm font-medium text-gray-500">M</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in this.analytics?.claims_amount"
            :key="index"
            class="hover:bg-gray-50"
            :class="{ 'bg-green-200 text-white': selectedStores.includes(item.entity_name) }"
          >
            <td class="px-4 py-2 text-sm text-gray-500">{{ index + 1 }}</td>
            <td class="px-4 py-2 text-sm text-gray-700">{{ item.entity_name }}</td>
            <td class="px-4 py-2 text-sm text-gray-700">{{ this.formatPrice(item.claim_amount) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import MultiSelect from "@/components/utils/MultiSelect.vue";
import AnalyticsService from "@/api/services/analytics.js";
import { isPresent } from "@/utils/validation";
import { MonthPicker } from "@estaly/ui";
import { formatDate } from "@/utils/date_formatter";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import { mapActions } from "vuex";

export default {
  components: {
    MonthPicker,
    GenericButton,
    MultiSelect,
  },
  data() {
    return {
      dateRange: [],
      analytics: null,
      selectedStores: [],
      remunerationAverage: null,
      salesAverage: null,
      claimsAmountAverage: null,
      token: null,
    };
  },

  mounted() {
    const token = this.$route.query.token;
    if (!token) {
      this.notify({
        category: "simple",
        type: "error",
        title: "Un token est requis pour accéder à cette page.",
      });
      return;
    }

    this.token = token;
    this.withLoader(this.fetchAnalytics);
  },

  computed: {
    params() {
      return {
        start_date: formatDate(this.dateRange[0]),
        end_date: formatDate(this.dateRange[1]),
        token: this.token,
      };
    },
    storeOptions() {
      return this.analytics?.remunerations
        .map((item) => ({
          label: item.entity_name,
          value: item.entity_name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    selectedRemunerations() {
      return this.analytics?.remunerations.filter((item) => this.selectedStores.includes(item.entity_name));
    },
    selectedSales() {
      return this.analytics?.sales.filter((item) => this.selectedStores.includes(item.entity_name));
    },
    selectedClaimsAmounts() {
      return this.analytics?.claims_amount.filter((item) => this.selectedStores.includes(item.entity_name));
    },
    selectedTotalRemuneration() {
      return this.selectedRemunerations.reduce((sum, item) => sum + Number(item.amount), 0);
    },
    selectedTotalSales() {
      return this.selectedSales.reduce((sum, item) => sum + item.sales, 0);
    },
    selectedTotalClaimsAmount() {
      return this.selectedClaimsAmounts.reduce((sum, item) => sum + Number(item.claim_amount), 0);
    },
    totalAverageRemuneration() {
      return (
        this.selectedRemunerations.reduce((sum, item) => sum + Number(item.amount), 0) /
        this.selectedRemunerations.length
      );
    },
    totalAverageSales() {
      return this.selectedSales.reduce((sum, item) => sum + item.sales, 0) / this.selectedSales.length;
    },
    totalAverageClaimsAmount() {
      return (
        this.selectedClaimsAmounts.reduce((sum, item) => sum + Number(item.claim_amount), 0) /
        this.selectedClaimsAmounts.length
      );
    },
    totalRemuneration() {
      return this.analytics?.remunerations.reduce((sum, item) => sum + Number(item.amount), 0);
    },
    totalSales() {
      return this.analytics?.sales.reduce((sum, item) => sum + item.sales, 0);
    },
    totalClaimsAmount() {
      return this.analytics?.claims_amount.reduce((sum, item) => sum + Number(item.claim_amount), 0);
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapActions("store", ["withLoader"]),
    formatPrice,
    isPresent,

    redirectToStore() {
      window.open("https://store.estaly.co", "_blank");
    },

    redirectToJoinEstalyPage() {
      window.open("https://airtable.com/app7bZfSpbgoO64ZG/pagHyEEP5IaW9FwgC/form", "_blank");
    },

    comparisonPercentage(value, average) {
      if (!value || !average) return 0;
      const percentage = ((value - average) / average) * 100;
      return percentage.toFixed(2);
    },

    comparisonClass(value, average) {
      if (!value || !average) return "text-gray-500";
      return value > average ? "text-green-500" : "text-red-500";
    },

    async fetchAnalytics() {
      let response = await AnalyticsService.computeOrganizationAnalytics(this.params);
      if (response?.success) {
        this.analytics = response.data.analytics;
        this.remunerationAverage = this.analytics.remuneration_average;
        this.salesAverage = this.analytics.sales_average;
        this.claimsAmountAverage = this.analytics.claims_amount_average;
      }
    },

    dateRangeUpdated(dateRange) {
      this.dateRange = dateRange;
      this.withLoader(this.fetchAnalytics);
    },
  },
};
</script>
