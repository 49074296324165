<template>
  <div v-if="isPresent(challenge)" class="p-6 bg-white">
    <component
      :is="challengeComponent"
      v-bind:challenge="challenge"
      v-bind:sellers="performance.sellers"
      v-bind:organizationSellers="performance.organizationSellers"
      v-bind:matchedContracts="performance.matchedContracts"
      v-bind:storePerformance="performance.storePerformance"
      v-bind:selectedStore="performance.selectedStore"
    />
  </div>
</template>

<script>
import { isPresent } from "@/utils/validation";

import SalesTargetChallenge from "@/components/challenge/SalesTargetChallenge.vue";
import PointsChallenge from "@/components/challenge/PointsChallenge.vue";

export default {
  components: { SalesTargetChallenge, PointsChallenge },

  props: ["challenge", "performance"],

  computed: {
    challengeComponent() {
      return this.challenge?.type === "sales" ? "SalesTargetChallenge" : "PointsChallenge";
    },
  },

  methods: {
    isPresent,
  },
};
</script>
