<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 flex z-50 md:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-primary-light">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 flex justify-between items-center px-4">
              <img class="h-8 w-auto" src="@/assets/large_logo_estaly_black.png" alt="Estaly" />
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <AdminSelector class="px-2 ml-4 pb-4" />
              <nav class="px-2 space-y-1">
                <router-link
                  v-for="item in this.navigationItems"
                  v-bind:key="item.name"
                  v-bind:to="item.to"
                  v-bind:class="[
                    this.isCurrentActivePage(item.name) ? 'bg-primary-lighter' : 'hover:bg-primary-lighter',
                    'group flex items-center px-2 py-2 text-base font-body-bold uppercase rounded-md text-primary-dark',
                  ]"
                >
                  <component
                    v-bind:is="item.icon"
                    class="mr-4 flex-shrink-0 h-6 w-6 text-primary-dark"
                    aria-hidden="true"
                  />
                  {{ $t("Navigation." + item.name) }}
                </router-link>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Store setup banner -->
    <StoreSetupBanner v-if="showStoreSetupBanner" />

    <!-- Static sidebar for desktop -->
    <div
      class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0"
      :class="{ 'pt-10': showStoreSetupBanner, 'pt-0': !showStoreSetupBanner }"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col flex-grow pt-5 overflow-y-auto bg-primary-light">
        <div class="flex items-center justify-between flex-shrink-0 px-4">
          <img class="h-10 w-auto" src="@/assets/large_logo_estaly_black.png" alt="Estaly" />
          <Menu as="div" class="ml-3 relative">
            <div>
              <MenuButton
                class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                <span class="sr-only">Open user menu</span>
                <div class="h-10 w-10 rounded-full bg-primary flex items-center justify-center">
                  <p class="text-white">{{ currentUser.initials }}</p>
                </div>
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <MenuItem v-slot="{ active }">
                  <router-link
                    to="/admin/profile"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                  >
                    {{ $t("Profile") }}
                  </router-link>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <span
                    v-on:click="handleSignout"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                  >
                    {{ $t("Sign out") }}
                  </span>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <div class="mt-5 flex-1 flex flex-col">
          <AdminSelector class="px-2 ml-4 pb-4" />
          <nav class="flex-1 px-2 pb-4 space-y-1">
            <router-link
              v-for="item in this.navigationItems"
              :key="item.name"
              :to="item.to"
              :class="[
                this.isCurrentActivePage(item.name) ? 'bg-primary-lighter' : 'hover:bg-primary-lighter',
                'group flex items-center px-2 py-2 text-sm font-body-bold uppercase rounded-md text-primary-dark',
              ]"
            >
              <component :is="item.icon" class="mr-3 flex-shrink-0 h-6 w-6 text-primary-dark" aria-hidden="true" />
              {{ $t("Navigation." + item.name) }}
            </router-link>
          </nav>
        </div>
      </div>
    </div>
    <div
      class="md:pl-64 flex flex-col flex-1"
      :class="{ 'pt-10': showStoreSetupBanner, 'pt-0': !showStoreSetupBanner }"
    >
      <div class="sm:hidden sticky z-20 flex-shrink-0 top-0 flex h-16 bg-white shadow">
        <button
          type="button"
          class="px-4border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary md:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 px-4 flex justify-between">
          <div class="flex-1 flex"></div>
          <div class="ml-4 flex items-center md:ml-6">
            <div class="p-2">
              <a
                class="flex items-center justify-center cursor-pointer"
                target="_blank"
                href="https://estaly.notion.site/FAQ-54e8759929b34ae6a87d05f93abc631e"
              >
                <QuestionMarkCircleIcon class="h-5 w-5 text-gray-500" />
                <p class="ml-1 text-sm text-gray-500">Help</p>
              </a>
            </div>
            <Menu as="div" class="ml-3 relative">
              <div>
                <MenuButton
                  class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                  <span class="sr-only">Open user menu</span>
                  <div class="h-10 w-10 rounded-full bg-primary flex items-center justify-center">
                    <p class="text-white">{{ currentUser.initials }}</p>
                  </div>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem v-slot="{ active }">
                    <router-link
                      to="/admin/profile"
                      :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                    >
                      Profile
                    </router-link>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <span
                      v-on:click="handleSignout"
                      :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                    >
                      {{ $t("Sign out") }}
                    </span>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
      <div class="relative min-h-screen">
        <LayoutLoading />
        <main>
          <div class="pt-6 pb-4 pb-24">
            <div class="max-w-7xl mx-auto px-4">
              <router-view></router-view>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
  CurrencyEuroIcon,
  CloudUploadIcon,
  QuestionMarkCircleIcon,
  ShoppingCartIcon,
  ViewListIcon,
  HomeIcon,
  UserCircleIcon,
  PresentationChartLineIcon,
  ArchiveIcon,
  FireIcon,
  SparklesIcon,
} from "@heroicons/vue/outline";

import { SearchIcon } from "@heroicons/vue/solid";
import { mapActions, mapGetters, mapState } from "vuex";
import AdminSelector from "../components/selector/AdminSelector.vue";
import { isPresent } from "@/utils/validation";
import { initIntercom } from "@/utils/intercom.js";
import StoreSetupBanner from "@/components/utils/StoreSetupBanner.vue";
import LayoutLoading from "@/components/utils/LayoutLoading.vue";

const navigation = [
  {
    name: "Management",
    to: "/admin",
    icon: PresentationChartLineIcon,
    permission: "admin",
  },
  {
    name: "Remuneration",
    to: "/admin/invoices",
    icon: CurrencyEuroIcon,
    permission: "invoices",
  },
  {
    name: "Retribution",
    to: "/admin/retributions",
    icon: SparklesIcon,
    permission: "invoices",
  },
  {
    name: "Challenge",
    to: "/admin/challenge",
    icon: FireIcon,
    permission: "admin",
  },
  {
    name: "Plan sales",
    to: "/admin/plan-sales",
    icon: ArchiveIcon,
    permission: "plan_sales",
  },
  {
    name: "Claim List",
    to: "/admin/claim",
    icon: ViewListIcon,
    permission: "claims",
  },
  {
    name: "Users",
    to: "/admin/users",
    icon: UserCircleIcon,
    permission: "users",
  },
  {
    name: "My organization",
    to: "/admin/organization",
    icon: HomeIcon,
    permission: "stores",
  },
];

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    CloudUploadIcon,
    LayoutLoading,
    QuestionMarkCircleIcon,
    ShoppingCartIcon,
    HomeIcon,
    ArchiveIcon,
    SparklesIcon,
    AdminSelector,
    StoreSetupBanner,
  },

  data: () => {
    return {
      navigation,
      sidebarOpen: false,
    };
  },

  watch: {
    async $route(to, from) {
      this.updateNavigation();
      if (to.path != from.path) await this.refreshCurrentUser();
    },
  },

  beforeMount() {
    this.updateNavigation();
  },

  mounted() {
    document.title = "Estaly - Dashboard";
    initIntercom(this.currentUser.email, this.currentUser.intercomUserHash, this.currentUser.firstName);
  },

  computed: {
    ...mapState("auth", ["currentUser"]),
    ...mapGetters("navigation", ["isCurrentActivePage"]),
    ...mapGetters("auth", ["currentUserHasPermission"]),

    showStoreSetupBanner() {
      return this.currentUser.storeToSetup && this.currentUserHasPermission("stores");
    },

    navigationItems() {
      if (!this.isOrganizationWeb) return this.authorizedNavigationItems;

      return this.authorizedNavigationItems.filter((item) => item.name !== "Retribution" && item.name !== "Challenge");
    },

    authorizedNavigationItems() {
      return this.navigation.filter((navigationItem) => this.currentUserHasPermission(navigationItem.permission));
    },

    isOrganizationWeb() {
      return this.currentUser.organization?.source === "web";
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapActions("auth", ["signout", "refreshCurrentUser"]),
    ...mapActions("store", ["reinitializeStoreState"]),
    ...mapActions("navigation", ["updateActivePage", "resetActivePage"]),

    async handleSignout() {
      const response = await this.signout();
      if (!response?.success) return;

      this.$router.push("/signin");
      this.notify({
        category: "simple",
        type: "success",
        title: "You successfully signed out.",
      });
      this.reinitializeStoreState();
    },

    updateNavigation() {
      const activePage = isPresent(this.$route.meta)
        ? navigation.find((item) => item["name"] === this.$route.meta.navigation)
        : null;

      if (isPresent(activePage)) {
        this.updateActivePage(activePage);
      } else {
        this.resetActivePage();
      }
    },
  },
};
</script>
