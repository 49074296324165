<template>
  <div v-if="marketplace" class="bg-white pt-4 px-4 pb-2 mb-2 rounded-md">
    <RadioGroup v-model="productCategory" class="mb-2">
      <RadioGroupLabel class="text-xl font-heading-1 font-body-bold pb-2 tracking-tight text-gray-900">
        {{ $t("Type of insurance") }}
      </RadioGroupLabel>

      <div class="flex gap-4 mt-2 flex-col sm:flex-row">
        <RadioGroupOption
          as="template"
          v-for="category in categories"
          :key="category.name"
          :value="category"
          v-slot="{ checked }"
        >
          <div
            :class="[
              'text-xs cursor-pointer flex items-center p-2 border-2 rounded-md shadow-sm focus:outline-none',
              checked ? 'border-primary text-button-font-primary bg-primary' : 'bg-white text-gray-700',
            ]"
          >
            {{ $t(category.name) }}
          </div>
        </RadioGroupOption>
      </div>
    </RadioGroup>
  </div>
</template>

<script>
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { EventBus } from "@/utils/event_bus";

export default {
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
  },

  props: ["categories"],

  computed: {
    marketplace() {
      return this.categories?.length > 1;
    },
  },

  mounted() {
    EventBus.on("resetProductCategory", this.resetSelectedProductCategory);

    // Sélectionne automatiquement la seule catégorie disponible s'il n'y en a qu'une
    if (this.categories.length === 1) {
      this.productCategory = this.categories[0];
      this.$emit("selectProductCategory", this.categories[0]);
    }
  },

  data: () => {
    return {
      productCategory: null,
    };
  },

  watch: {
    productCategory(newProductCategory) {
      this.$emit("selectProductCategory", newProductCategory);
    },

    // Surveille les changements de catégories pour ajuster la sélection
    categories: {
      handler(newCategories) {
        if (newCategories.length === 1) {
          this.productCategory = newCategories[0];
          this.$emit("selectProductCategory", newCategories[0]);
        }
      },
      immediate: true, // S'assure que cela s'exécute dès la création du composant
    },
  },

  methods: {
    resetSelectedProductCategory() {
      if (this.categories.length === 1) {
        this.productCategory = this.categories[0];
      } else {
        this.productCategory = null;
      }
      this.$emit("selectProductCategory", this.productCategory);
    },
  },
};
</script>
