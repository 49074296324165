<template>
  <div>
    <ClaimList :claims="claims" :current-page="currentPage" @redirectToClaimDetailsPage="redirectToClaimDetailsPage" />
    <Pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      :totalCount="totalCount"
      @changePage="handleChangePage"
    />
  </div>
</template>

<script>
import ClaimList from "@/components/claim/list/ClaimList.vue";
import { Pagination } from "@estaly/ui";

export default {
  components: {
    Pagination,
    ClaimList,
  },

  props: ["claims", "currentPage", "totalPages", "totalCount"],

  methods: {
    handleChangePage: function (page) {
      this.$emit("changePage", page);
    },

    redirectToClaimDetailsPage: function (claimId) {
      this.$emit("redirectToClaimDetailsPage", claimId);
    },
  },
};
</script>
