<template>
  <div class="flex flex-col gap-y-4">
    <div class="bg-white rounded-xl shadow">
      <div class="flex justify-between items-center px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-bold text-gray-900">Montant de la prime : {{ formatPrice(primeAmount) }}</h3>
        <Selector
          id="seller_id"
          label="Vendeur"
          name="seller_id"
          v-model="selectedSellerId"
          :options="sellerOptions"
          class="mb-5"
        />
      </div>
    </div>

    <Loading v-if="loadingRetributions" />
    <RetributionTable :retributions="retributions" v-else-if="selectedSellerId && retributions.length > 0">
      <template #seller_retribution="{ retributionId }">
        <span>{{ retributionAmount(retributionId) }}</span>
      </template>
    </RetributionTable>
    <Alert v-else-if="selectedSellerId && retributions.length === 0">
      {{ $t("no_data") }}
    </Alert>
  </div>
</template>

<script>
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";
import { Alert, Loading } from "@estaly/ui";
import AdminService from "@/api/services/admin";
import StoreService from "@/api/services/store";
import { Selector } from "@estaly/ui";
import RetributionTable from "@/components/retribution/RetributionTable.vue";

export default {
  components: { Selector, RetributionTable, Alert, Loading },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    totalRetribution: 0, // Retribution for all sellers
    sellerRetributions: {}, // Retribution for one seller : { retributionId: amount }
    sellersFilter: [],
    selectedSellerId: null,
    retributions: [],
    loadingRetributions: false,
  }),
  computed: {
    primeAmount() {
      if (this.selectedSellerId) {
        return Object.values(this.sellerRetributions).reduce((acc, amount) => acc + parseFloat(amount), 0);
      } else {
        return this.totalRetribution;
      }
    },
    sellerOptions() {
      const options = [{ value: null, label: "Tous les vendeurs" }];
      return options.concat(
        this.sellersFilter.map((seller) => ({
          value: seller.id,
          label: seller.name,
        })),
      );
    },
    selectedSellerStoreId() {
      return this.sellersFilter.find((seller) => seller.id == this.selectedSellerId)?.store_id;
    },
  },

  async created() {
    await this.fetchInvoiceSellerRetributions();
  },

  methods: {
    formatPrice,

    async fetchInvoiceSellerRetributions() {
      this.sellerRetributions = {};
      const response = await AdminService.getInvoiceSellerRetributions(this.invoice.id, this.selectedSellerId);
      if (!response.success) return;

      this.sellersFilter = response.data.filters.sellers_option;
      if (this.selectedSellerId) {
        this.sellerRetributions = response.data.amount;
      } else {
        this.totalRetribution = response.data.amount;
      }
      if (this.selectedSellerStoreId) await this.fetchRetributions();
    },

    async fetchRetributions() {
      this.loadingRetributions = true;
      const response = await StoreService.getRetributions(this.selectedSellerStoreId);
      this.loadingRetributions = false;
      if (!response.success) return;

      this.retributions = response.data.filter((retribution) => this.retributionAmount(retribution.id) !== "—");
    },

    retributionAmount(retributionId) {
      const amount = this.sellerRetributions[retributionId];
      return amount ? formatPrice(amount) : "—";
    },
  },

  watch: {
    async selectedSellerId() {
      await this.fetchInvoiceSellerRetributions();
    },
  },
};
</script>
