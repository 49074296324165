import { securedAxiosInstance } from "../axios";

import { ADMIN_PLAN_SALES_URL } from "./constants";
import { toSnakeCase } from "@estaly/ui/src/utils/data_formatter";

class AdminService {
  getPlanSales(params) {
    return securedAxiosInstance.post(ADMIN_PLAN_SALES_URL, params);
  }

  getPlanSale(planSaleId) {
    return securedAxiosInstance.get(`admin/plan_sales/${planSaleId}`);
  }

  getClaims({ data }) {
    return securedAxiosInstance.get(`/admin/claims`, {
      params: {
        stores: data.stores,
        status: data.status,
        page: data.page,
        keywords: data.keywords,
      },
    });
  }

  getClaimByFriendlyId(friendlyId) {
    return securedAxiosInstance.get(`/claims/${friendlyId}`);
  }

  getInvoices(page, filtering_data) {
    return securedAxiosInstance.post(`/admin/invoices?page=${page}`, filtering_data);
  }

  getInvoiceByCustomId(customId) {
    return securedAxiosInstance.get(`/admin/invoices/${customId}`);
  }

  getInvoiceSalesBreakdown(customId, filtering_data) {
    return securedAxiosInstance.get(`/admin/invoices/${customId}/sales_breakdown`, {
      params: filtering_data,
    });
  }

  getInvoiceBreakdown(customId, data) {
    return securedAxiosInstance.get(`/admin/invoices/${customId}/breakdown`, {
      params: data,
    });
  }

  getInvoiceSellerRetributions(invoiceId, sellerId) {
    return securedAxiosInstance.get(`/admin/invoices/${invoiceId}/seller_retributions`, {
      params: { seller_id: sellerId },
    });
  }

  getInvoiceItems(customId) {
    return securedAxiosInstance.get(`/admin/invoices/${customId}/invoice_items`);
  }

  addQuotationToClaim(claimFriendlyId, formData, repairCost) {
    return securedAxiosInstance.post(
      `/admin/claims/quotations?claim_id=${claimFriendlyId}&repair_cost=${repairCost}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  }

  addInvoiceToClaim(claimFriendlyId, formData, repairCost) {
    return securedAxiosInstance.post(
      `/admin/claims/invoices?claim_id=${claimFriendlyId}&repair_cost=${repairCost}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  }

  getPlanSaleCSVExport(filterParams) {
    return securedAxiosInstance.get("/admin/plan_sales/reporting", {
      params: toSnakeCase(filterParams),
    });
  }

  getPlanSaleBillingInvoice(planSaleId, planSaleBillingId) {
    return securedAxiosInstance.get(`/admin/plan_sales/${planSaleId}/plan_sale_billings/${planSaleBillingId}`);
  }

  updatePlanSale(planSaleId, formData) {
    return securedAxiosInstance.put(`/admin/plan_sales/${planSaleId}`, formData);
  }

  getPlanSaleCancellationReasons() {
    return securedAxiosInstance.get("/admin/plan_sales/cancellation_reasons");
  }

  cancelPlanSale(planSaleId, formData) {
    return securedAxiosInstance.put(`/admin/plan_sales/${planSaleId}/cancel`, formData);
  }
}

export default new AdminService();
