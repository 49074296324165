<template>
  <div class="pt-6 md:pt-0 relative overflow-x-auto">
    <Table :columns="tableColumns" :data="formattedData" is-accessible @view="retrieveChallengePerformances" />
  </div>
  <Modal :size="'big'" :open="openModal" @close="openModal = false">
    <ChallengePerformances :challenge="challenge" :performance="performance" />
  </Modal>
</template>

<script>
import { Table } from "@estaly/ui";
import { toCamelCase } from "@estaly/ui/src/utils/data_formatter";
import { Modal } from "@estaly/ui";
import ChallengePerformances from "@/components/challenge/ChallengePerformances.vue";

import StoreService from "@/api/services/store";

export default {
  components: { Table, Modal, ChallengePerformances },

  props: ["challenges", "storeId"],

  data() {
    return {
      openModal: false,
      challenge: {},
      performance: {},
    };
  },

  computed: {
    tableColumns() {
      return [
        { field: "title", label: "Nom" },
        { field: "startDate", label: "Début" },
        { field: "endDate", label: "Fin" },
      ];
    },
    formattedData() {
      return this.challenges.map((challenge) => ({
        id: challenge.id,
        title: challenge.title,
        startDate: challenge.startDate,
        endDate: challenge.endDate,
      }));
    },
  },

  methods: {
    async retrieveChallengePerformances(challenge) {
      const response = await StoreService.getChallengesAndSellersPerformance(this.storeId, challenge.id);
      if (response?.success) {
        const data = toCamelCase(response.data);
        this.challenge = data.challenge;
        this.performance = data.performance;
        this.openModal = true;
      }
    },
  },
};
</script>
