<template>
  <div>
    <IbanMissing v-if="isStoreInvoiceLevel && anyWithMissingIban" />
    <div class="flex flex-row justify-between">
      <h1 class="text-2xl font-heading-1 tracking-tight text-gray-900">
        {{ $t("Stores") }}
      </h1>
      <GenericButton v-if="currentUserHasPermission('admin')" @click="createStore">
        {{ $t("Add a store") }}
      </GenericButton>
    </div>
    <SearchBar
      class="my-4"
      :current-page="currentPage"
      :placeholder="'Recherche de point de vente par nom/url'"
      :initial-keywords="keywords"
      :available-filters="availableFilters"
      :selected-filters="selectedFilters"
      @updateKeywordSearch="updateKeywordSearch"
      @updateFilterSearch="updateFilterSearch"
    />
    <Table
      class="mt-10"
      :columns="columns"
      :data="stores"
      :is-row-clickable="true"
      @rowClick="redirectToStoreDetailsPage"
    >
      <template #storeName="{ row }">
        <span v-if="isPresent(row.salesChannel)">{{ row.salesChannel }}</span>
        <span v-else-if="isPresent(row.setupBy)" class="text-gray-400">Référent : {{ row.setupBy }}</span>
      </template>
      <template #storeStatus="{ row }">
        <FieldSpan
          :style-to-add="getStoreStatusFieldDisplayStyle(storeStatusOrSetup(row))"
          :value="storeStatusOrSetup(row)"
        />
        <FieldSpan v-if="ibanMissing(row)" style-to-add="bg-red-100 text-black ml-4" value="Iban manquant" />
      </template>
      <template #actions="{ row }">
        <TrashIcon v-if="row.status === 'setup'" class="h-5 cursor-pointer" @click.stop="openDisableStoreModal(row)" />
      </template>
    </Table>
    <Pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      :totalCount="totalCount"
      @changePage="handleChangePage"
    />
    <DisableModal
      :open="disableStoreModalOpen"
      :storeId="disableStoreId"
      @closeDisableStoreModal="disableStoreModalOpen = false"
      @disableStore="disableStore"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import StoreService from "@/api/services/store";
import SearchBar from "@/components/menu/SearchBar.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import DisableModal from "@/components/store/DisableModal.vue";
import FieldSpan from "@/components/utils/FieldSpan.vue";
import IbanMissing from "@/components/IbanMissing.vue";
import { TrashIcon } from "@heroicons/vue/outline";
import { Table } from "@estaly/ui";
import { isPresent } from "@/utils/validation";
import { toCamelCase } from "@estaly/ui/src/utils/data_formatter";
import { Pagination } from "@estaly/ui";

export default {
  components: {
    Pagination,
    SearchBar,
    GenericButton,
    DisableModal,
    FieldSpan,
    IbanMissing,
    Table,
    TrashIcon,
  },

  data() {
    return {
      stores: [],
      currentPage: 1,
      keywords: null,
      availableFilters: {
        status: [],
        iban: [],
      },
      selectedFilters: {
        status: [],
        iban: [],
      },
      totalPages: null,
      totalCount: null,
      disableStoreModalOpen: false,
      disableStoreId: null,
      invoiceLevel: null,
      anyWithMissingIban: null,
    };
  },

  computed: {
    ...mapState("store", ["isLoading"]),
    ...mapGetters("auth", ["currentUserHasPermission"]),

    isStoreInvoiceLevel() {
      return this.invoiceLevel === "store";
    },

    columns() {
      return [
        {
          field: "storeName",
          label: "Nom",
          slot: "storeName",
          bold: true,
        },
        {
          field: "storeStatus",
          label: "Statut",
          slot: "storeStatus",
        },
        {
          field: "actions",
          label: "",
          slot: "actions",
        },
      ];
    },

    status: {
      get() {
        return this.selectedFilters.status;
      },
      set(value) {
        if (!isPresent(value)) return (this.selectedFilters.status = []);

        this.selectedFilters.status = Array.isArray(value) ? value : [value];
      },
    },

    iban: {
      get() {
        return this.selectedFilters.iban;
      },
      set(value) {
        if (!isPresent(value)) return (this.selectedFilters.iban = []);

        this.selectedFilters.iban = Array.isArray(value) ? value : [value];
      },
    },

    searchParams() {
      return {
        ...this.$route.query,
        page: this.$route.query.page ? parseInt(this.$route.query.page, 10) : this.currentPage,
        status: isPresent(this.status) ? this.status : ["active", "setup"],
        iban: this.iban,
      };
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapActions("store", ["withLoader"]),
    isPresent,

    async retrieveStores() {
      const response = await StoreService.getStores(this.searchParams);
      if (response?.success) {
        const data = toCamelCase(response.data);
        this.stores = data.stores;
        this.availableFilters = data.filters;
        this.invoiceLevel = data.invoiceLevel;
        this.anyWithMissingIban = data.anyWithMissingIban;
        this.totalCount = data.page.totalCount;
        this.totalPages = data.page.totalPages;
      }
    },

    updateRouteQueryParams() {
      let query = { tab: "stores" };
      if (this.currentPage) query.page = this.currentPage;
      if (this.keywords) query.keywords = this.keywords;
      if (this.status) query.status = this.status;
      if (this.iban) query.iban = this.iban;
      this.$router.push({ query });
    },

    loadQueryParams() {
      this.currentPage = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
      this.keywords = this.$route.query.keywords || null;
      this.status = this.$route.query.status || [];
      this.iban = this.$route.query.iban || [];
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.updateRouteQueryParams();
    },

    updateKeywordSearch(keywords) {
      this.keywords = keywords;
      this.currentPage = 1;
      this.updateRouteQueryParams();
    },

    updateFilterSearch(filterName, filterOptions) {
      this.selectedFilters[filterName] = filterOptions;
      this.currentPage = 1;
      this.updateRouteQueryParams();
    },

    async createStore() {
      const response = await StoreService.createStore();
      if (response?.success) {
        this.notify({
          category: "simple",
          type: "success",
          title: "Nouveau point de vente créé",
          text: "Vous pouvez maintenant compléter les informations de votre point de vente",
        });
      }
      await this.retrieveStores(1, null);
    },

    storeStatusOrSetup(store) {
      return store.setupStatus || store.status;
    },

    getStoreStatusFieldDisplayStyle(status) {
      switch (status) {
        case "active":
          return "bg-green-100 text-green-800";
        case "inactive":
          return "bg-red-100 text-red-800";
        case "pending_verification":
          return "bg-blue-100 text-blue-800";
        case "information_to_be_completed":
          return "bg-yellow-100 text-yellow-800";
      }
    },

    ibanMissing(store) {
      return !store.ibanId && this.isStoreInvoiceLevel && store.status === "active";
    },

    redirectToStoreDetailsPage(store) {
      this.$router.push(`/admin/organization/stores/${store.id}`);
    },

    isStoreSetup(store) {
      return store.status === "setup";
    },

    openDisableStoreModal(store) {
      this.disableStoreId = store.id;
      this.disableStoreModalOpen = true;
    },

    async disableStore(storeId) {
      this.disableStoreModalOpen = false;
      await this.withLoader(async () => {
        const response = await StoreService.updateStoreStatus(storeId, "inactive");
        if (response?.success) {
          await this.notify({
            category: "simple",
            type: "success",
            title: "Mise à jour effectuée",
          });
        }
      });
      await this.retrieveStores(this.currentPage, this.keywords);
    },
  },

  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        this.loadQueryParams();
        this.retrieveStores();
      },
    },
  },
};
</script>
