import StoreService from "@/api/services/store";
import AdminService from "@/api/services/admin";
import { isPresent } from "@/utils/validation";
import { setQueryParams, arrayFilterIfPresent } from "@/utils/query_params";

const initialState = {
  availableFilters: {
    billing: [],
    chargedBy: [],
    status: [],
    stores: [],
  },
  selectedFilters: {
    billing: [],
    chargedBy: [],
    status: [],
    stores: [],
  },
  planSales: [],
  totalCount: null,
  totalPages: null,
  keywords: "",
  startDate: null,
  endDate: null,
  isAdmin: null,
  selectedStoreId: null,
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    getDateRange: (state) => {
      return { startDate: state.startDate, endDate: state.endDate };
    },
  },

  mutations: {
    setPlanSales(state, { planSales, totalCount, totalPages }) {
      state.planSales = planSales;
      state.totalCount = totalCount;
      state.totalPages = totalPages;
    },

    setFilter(state, { filterName, filterOptions }) {
      state.selectedFilters[filterName] = filterOptions;
    },

    setKeywords(state, keywords) {
      state.keywords = keywords;
    },

    setAvailableFilters(state, { billing, chargedBy, status, stores }) {
      state.availableFilters.billing = billing;
      state.availableFilters.chargedBy = chargedBy;
      state.availableFilters.status = status;
      state.availableFilters.stores = stores;
    },

    setSelectedFilters(state, { billing, chargedBy, status, stores }) {
      state.selectedFilters.billing = billing;
      state.selectedFilters.chargedBy = chargedBy;
      state.selectedFilters.status = status;
      state.selectedFilters.stores = stores;
    },

    setDates(state, { startDate, endDate }) {
      state.startDate = startDate;
      state.endDate = endDate;
    },

    setIsAdmin(state, { isAdmin }) {
      state.isAdmin = isAdmin;
    },

    setSelectedStoreId(state, { selectedStoreId }) {
      state.selectedStoreId = selectedStoreId;
    },

    setFilters(state, { filters }) {
      state.selectedFilters.billing = arrayFilterIfPresent(filters.billing);
      state.selectedFilters.chargedBy = arrayFilterIfPresent(filters.charged_by);
      state.selectedFilters.status = arrayFilterIfPresent(filters.status);
      state.keywords = filters.keywords || state.keywords;
      state.startDate = filters.start_date || state.startDate;
      state.endDate = filters.end_date || state.endDate;
      state.selectedFilters.stores = arrayFilterIfPresent(filters.stores);
    },
  },

  actions: {
    async getPlanSales({ state, commit }, { page, isAdmin, storeId }) {
      commit("setIsAdmin", { isAdmin: isAdmin });

      if (isPresent(storeId)) {
        commit("setSelectedStoreId", { selectedStoreId: storeId });
      }

      const planSalesParams = {
        billing: state.selectedFilters.billing,
        charged_by: state.selectedFilters.chargedBy,
        status: state.selectedFilters.status,
        keywords: state.keywords,
        page: page,
        start_date: state.startDate,
        end_date: state.endDate,
      };
      if (state.isAdmin) {
        planSalesParams.stores = state.selectedFilters.stores;
      }

      setQueryParams(planSalesParams);

      let response;
      if (state.isAdmin) {
        response = await AdminService.getPlanSales(planSalesParams);
      } else {
        response = await StoreService.getPlanSales(planSalesParams, state.selectedStoreId);
      }
      if (!response?.success) return response;

      commit("setPlanSales", {
        planSales: response.data.planSales,
        totalCount: response.data.page.totalCount,
        totalPages: response.data.page.totalPages,
      });

      commit("setAvailableFilters", {
        billing: response.data.filters.billing,
        chargedBy: response.data.filters.chargedBy,
        status: response.data.filters.status,
        stores: response.data.filters.stores,
      });
      return response;
    },

    updateSearch({ commit, dispatch }, { searchString, page, isAdmin }) {
      commit("setKeywords", searchString);
      return dispatch("getPlanSales", {
        page: page,
        isAdmin: isAdmin,
      });
    },

    updateFilter({ commit, dispatch }, { filterName, filterOptions, page, isAdmin }) {
      commit("setFilter", { filterName, filterOptions });
      return dispatch("getPlanSales", {
        page: page,
        isAdmin: isAdmin,
      });
    },

    updateDateFilter({ commit, dispatch }, { startDate, endDate, page, isAdmin }) {
      commit("setDates", { startDate, endDate });
      return dispatch("getPlanSales", {
        page: page,
        isAdmin: isAdmin,
      });
    },

    resetFilters({ commit }) {
      commit("setKeywords", "");
      commit("setSelectedFilters", {
        billing: [],
        chargedBy: [],
        status: [],
        stores: [],
      });
      commit("setDates", {
        startDate: null,
        endDate: null,
      });
      commit("setSelectedStoreId", {
        selectedStoreId: null,
      });
    },

    setFilters({ commit }, { filters }) {
      commit("setFilters", { filters: filters });
    },

    async getPlanSaleCSVExport({ state }) {
      return await AdminService.getPlanSaleCSVExport({
        billing: state.selectedFilters.billing,
        charged_by: state.selectedFilters.chargedBy,
        status: state.selectedFilters.status,
        stores: state.selectedFilters.stores,
        keywords: state.keywords,
        start_date: state.startDate,
        end_date: state.endDate,
      });
    },
  },
};
