<template>
  <div class="px-2 my-4">
    <h1 class="text-4xl font-heading-1 tracking-tight text-gray-900">
      {{ $t("Challenges") }}
    </h1>
  </div>

  <div class="mb-6">
    <Selector
      id="store-selector"
      name="store"
      label="Magasin"
      :modelValue="selectedStore?.id"
      :options="storeOptions"
      @update:modelValue="handleStoreChange"
    />

    <Tabs :tabs="tabs" @tab-selected="handleTabChange" />

    <div v-if="selectedTab === 'current'">
      <div v-if="activeChallenge">
        <ChallengePerformances :challenge="challenge" :performance="performance" />
      </div>
      <div v-else class="p-6 bg-white">
        <p class="font-heading-1 uppercase text-2xl">Pas de challenge en cours.</p>
      </div>
    </div>

    <div v-else-if="selectedTab === 'all'">
      <ChallengeList :challenges="challenges" :storeId="selectedStore?.id" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import StoreService from "@/api/services/store";
import { Selector } from "@estaly/ui";
import { Tabs } from "@estaly/ui";
import ChallengePerformances from "@/components/challenge/ChallengePerformances.vue";
import ChallengeList from "@/components/challenge/ChallengeList.vue";

import { toCamelCase } from "@estaly/ui/src/utils/data_formatter";

export default {
  components: { Selector, Tabs, ChallengePerformances, ChallengeList },

  data() {
    return {
      challenge: {},
      performance: {},
      challenges: [],
      selectedStore: null,
      selectedTab: "current",
      tabs: [
        { name: "current", displayName: "En cours", current: true },
        { name: "all", displayName: "Historique", current: false },
      ],
    };
  },

  computed: {
    ...mapState("store", ["stores"]),
    storeOptions() {
      return this.stores.map((store) => ({
        value: store.id,
        label: store.salesChannel || store.name,
      }));
    },

    activeChallenge() {
      return this.challenges.find((challenge) => challenge.active);
    },
  },

  async mounted() {
    if (this.stores.length > 0) {
      this.selectedStore = this.stores[0];
      await this.retrieveChallenges(this.selectedStore.id);
    }
  },

  methods: {
    ...mapActions("store", ["withLoader"]),

    async retrieveChallenges() {
      await this.withLoader(async () => {
        const response = await StoreService.getChallenges(this.selectedStore.id);
        if (response?.success) {
          const data = toCamelCase(response.data);
          this.challenges = data.challenges;
        }
      });
    },

    async retrieveChallengePerformances(storeId, challengeId) {
      const response = await StoreService.getChallengesAndSellersPerformance(storeId, challengeId);
      if (response?.success) {
        const data = toCamelCase(response.data);
        this.challenge = data.challenge;
        this.performance = data.performance;
      }
    },

    async handleStoreChange(storeId) {
      const newStore = this.stores.find((store) => store.id === parseInt(storeId, 10));
      this.selectedStore = newStore;
      await this.retrieveChallenges();
    },

    handleTabChange(selectedTab) {
      this.selectedTab = selectedTab.name;
    },

    handleTabAndDataChange() {
      if (this.selectedTab === "current" && this.selectedStore && this.activeChallenge) {
        this.retrieveChallengePerformances(this.selectedStore.id, this.activeChallenge.id);
      }
    },
  },

  watch: {
    selectedTab: "handleTabAndDataChange",
    selectedStore: "handleTabAndDataChange",
    activeChallenge: "handleTabAndDataChange",
  },
};
</script>
