<template>
  <div>
    <LayoutLoading />
    <main>
      <router-view />
    </main>
  </div>
</template>
<script>
import LayoutLoading from "@/components/utils/LayoutLoading.vue";

export default {
  components: {
    LayoutLoading,
  },
};
</script>
