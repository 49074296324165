<template>
  <div>
    <PlanSaleListTitle />
    <PlanSaleListMenu @updatePage="updatePage" />
    <PlanSaleList
      :current-page="currentPage"
      :plan-sales="planSales"
      :total-pages="totalPages"
      :total-count="totalCount"
      @updatePage="updatePage"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PlanSaleList from "@/components/plansales/PlanSaleList.vue";
import PlanSaleListTitle from "@/components/plansales/PlanSaleListTitle.vue";
import PlanSaleListMenu from "@/components/plansales/PlanSaleListMenu.vue";

export default {
  components: {
    PlanSaleListTitle,
    PlanSaleList,
    PlanSaleListMenu,
  },

  data: () => {
    return {
      currentPage: 1,
    };
  },

  computed: {
    ...mapState("auth", ["signedIn"]),
    ...mapState("store", ["isLoading", "selectedStoreId"]),
    ...mapState("plan_sale_list", ["planSales", "totalCount", "totalPages"]),
  },

  created() {
    if (this.signedIn) {
      this.resetFilters();
      this.withLoader(this.retrievePlanSales);
    } else {
      this.$router.push("/signin");
    }
  },

  methods: {
    ...mapActions("store", ["withLoader"]),
    ...mapActions("plan_sale_list", ["getPlanSales", "resetFilters"]),

    async retrievePlanSales() {
      await this.getPlanSales({
        page: this.currentPage,
        isAdmin: false,
        storeId: this.selectedStoreId,
      });
    },

    updatePage(page, refresh = true) {
      this.currentPage = page;

      if (refresh) {
        this.withLoader(this.retrievePlanSales);
      }
    },
  },
};
</script>
