<template>
  <div>
    <div>
      <h1 class="text-4xl font-heading-1 pl-2 tracking-tight text-gray-900 my-4">
        {{ $t("My organization") }}
      </h1>
    </div>
    <div>
      <div class="mb-4">
        <div v-if="tabs.length > 1">
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
              <a
                v-for="tab in tabs"
                :key="tab.id"
                :href="generateTabLink(tab.id)"
                @click.prevent="selectTab(tab.id)"
                :class="[
                  tab.active
                    ? 'border-primary text-primary-dark'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer',
                ]"
              >
                {{ tab.name }}
              </a>
            </nav>
          </div>
        </div>
      </div>

      <component :is="currentTab.component" />
    </div>
  </div>
</template>

<script>
import OrganizationDetails from "@/components/organization/OrganizationDetails.vue";
import StoreList from "@/components/organization/StoreList.vue";

export default {
  components: {
    OrganizationDetails,
    StoreList,
  },

  data: () => {
    return {
      tabs: [],
    };
  },

  computed: {
    currentTab() {
      return this.tabs.find((tab) => tab.active);
    },
  },

  created() {
    this.initializeTabs();
    this.syncTabWithUrl();
  },

  watch: {
    $route: "syncTabWithUrl",
  },

  methods: {
    initializeTabs() {
      this.tabs = [
        {
          id: "general",
          name: "Informations générales",
          active: false,
          component: OrganizationDetails,
        },
        {
          id: "stores",
          name: "Points de vente",
          active: false,
          component: StoreList,
        },
      ];
    },

    selectTab(selectedTabId, replaceUrl = true) {
      this.tabs.forEach((tab) => {
        tab.active = tab.id === selectedTabId;
      });

      if (replaceUrl) {
        this.$router.push({ query: { tab: selectedTabId } });
      } else {
        this.$router.push({
          query: {
            ...this.$route.query,
            tab: selectedTabId,
          },
        });
      }
    },

    syncTabWithUrl() {
      const tabId = this.$route.query.tab || "general";
      if (!this.currentTab || tabId !== this.currentTab.id) {
        this.selectTab(tabId, false);
      }
    },

    generateTabLink(tabId) {
      return this.$router.resolve({ query: { tab: tabId } }).href;
    },
  },
};
</script>
