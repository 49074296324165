<template>
  <Modal :open="open" @close="closeModal" size="big">
    <div>
      <h1 class="flex items-center justify-center text-2xl font-bold">
        Veuillez télécharger la facture de ce sinistre
      </h1>
      <div class="rounded-md bg-yellow-50 p-4 mt-3 border-l-4 border-yellow-400">
        <div class="flex">
          <div class="shrink-0">
            <ExclamationIcon class="w-8 h-8 text-yellow-400" />
          </div>
          <div class="flex items-center ml-3 text-sm text-yellow-700">
            <p>Montant maximal de prise en charge : {{ maxCoverageCompensation }} €</p>
          </div>
        </div>
      </div>
      <h3 class="mt-6 text-sm">
        Veuillez téléverser la facture finale et détailler les coûts afin de clôturer le sinistre.
      </h3>
    </div>
    <FormField
      class="mt-5"
      id="file_input"
      v-model="file"
      required
      type="file"
      label="Veuillez charger la facture"
      field-class="block w-full text-sm text-gray-700 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
      name="invoice_upload"
    />
    <CostItems class="mt-6" :initial-items="costItems" @updatedItems="(items) => (costItems = items)" />
    <PricingDetails :claim="claim" :costItems="costItems" />
    <div v-if="file && costItems && costItems.length > 0" class="flex justify-center">
      <GenericButton class="mt-5" @onClick="send">Ajouter</GenericButton>
    </div>
  </Modal>
</template>
<script>
import { Modal } from "@estaly/ui";
import GenericButton from "@/components/utils/GenericButton.vue";
import { mapActions } from "vuex";
import PricingDetails from "@/components/claim/details/information/action/cost_items/PricingDetails.vue";
import CostItems from "@/components/claim/details/information/action/cost_items/CostItems.vue";
import { ExclamationIcon } from "@heroicons/vue/solid";
import FormField from "@/components/utils/FormField.vue";
export default {
  components: {
    FormField,
    ExclamationIcon,
    CostItems,
    PricingDetails,
    GenericButton,
    Modal,
  },
  props: ["claim", "open"],
  data: () => {
    return {
      file: null,
      costItems: null,
    };
  },

  computed: {
    maxCoverageCompensation() {
      return this.claim.coverages?.compensationValue;
    },
  },

  beforeMount() {
    this.costItems = this.claim.quotation?.costs;
  },

  methods: {
    ...mapActions("claims", ["uploadClaimInvoice"]),
    ...mapActions("notifications", ["notify"]),

    async send() {
      const response = await this.uploadClaimInvoice({
        costItems: this.costItems,
        invoiceFile: this.file,
      });
      if (response?.success) this.closeModal();
    },
    closeModal() {
      this.$emit("close");
      this.file = null;
      this.costItems = null;
    },
  },
};
</script>
