<template>
  <div v-if="invoiceItems">
    <CollapsableContent
      v-if="billInvoiceItems.length > 0"
      :title="`Facturé à ${invoice.entityName}`"
      :subtitle="`Total dû à Estaly : ${formatPrice(billAmount)}`"
      :open="false"
    >
      <div>
        <table class="w-full border-collapse bg-white shadow-md rounded-lg">
          <thead>
            <tr class="bg-gray-100">
              <th class="px-4 py-2 text-left text-sm font-medium text-gray-600">{{ $t("Customer") }}</th>
              <th class="px-4 py-2 text-left text-sm font-medium text-gray-600">{{ $t("Reference") }}</th>
              <th class="px-4 py-2 text-left text-sm font-medium text-gray-600">{{ $t("Nature") }}</th>
              <th class="px-4 py-2 text-left text-sm font-medium text-gray-600">{{ $t("Date") }}</th>
              <th class="px-4 py-2 text-right text-sm font-medium text-gray-600">{{ $t("Premium TTC") }}</th>
              <th class="px-4 py-2 text-right text-sm font-medium text-gray-600">{{ $t("Commission") }}</th>
              <th class="px-4 py-2 text-right text-sm font-medium text-gray-600">{{ $t("Amount owed") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in billInvoiceItems" :key="item.id" class="odd:bg-gray-50 even:bg-gray-100">
              <td class="px-4 py-2 text-sm text-gray-800">{{ item.customer }}</td>
              <td class="px-4 py-2 text-sm text-gray-800">{{ item.planSaleId }}</td>
              <td class="px-4 py-2 text-sm text-gray-800">
                {{ item.billingType === "collected" ? $t("subscription") : $t("termination") }}
              </td>
              <td class="px-4 py-2 text-sm text-gray-800">{{ item.date }}</td>
              <td class="px-4 py-2 text-sm text-right text-gray-800">{{ formatPrice(item.price) }}</td>
              <td class="px-4 py-2 text-sm text-right text-gray-800">{{ formatPrice(item.storeCommissionAmount) }}</td>
              <td class="px-4 py-2 text-sm text-right text-gray-800">{{ formatPrice(item.amount) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </CollapsableContent>

    <!-- Table for Statement Invoice Items -->
    <CollapsableContent
      v-if="statementInvoiceItems.length > 0"
      class="mt-4"
      :title="`Versé à ${invoice.entityName}`"
      :subtitle="`Total dû à ${invoice.entityName} : ${formatPrice(statementAmount)}`"
      :open="false"
    >
      <div>
        <table class="w-full border-collapse bg-white shadow-md rounded-lg">
          <thead>
            <tr class="bg-gray-100">
              <th class="px-4 py-2 text-left text-sm font-medium text-gray-600">{{ $t("Customer") }}</th>
              <th class="px-4 py-2 text-left text-sm font-medium text-gray-600">{{ $t("Reference") }}</th>
              <th class="px-4 py-2 text-left text-sm font-medium text-gray-600">{{ $t("Nature") }}</th>
              <th class="px-4 py-2 text-left text-sm font-medium text-gray-600">{{ $t("Date") }}</th>
              <th class="px-4 py-2 text-right text-sm font-medium text-gray-600">{{ $t("Premium TTC") }}</th>
              <th class="px-4 py-2 text-right text-sm font-medium text-gray-600">{{ $t("Commission") }}</th>
              <th class="px-4 py-2 text-right text-sm font-medium text-gray-600">{{ $t("Amount owed") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in statementInvoiceItems" :key="item.id" class="odd:bg-gray-50 even:bg-gray-100">
              <td class="px-4 py-2 text-sm text-gray-800">{{ item.customer }}</td>
              <td class="px-4 py-2 text-sm text-gray-800">{{ item.planSaleId }}</td>
              <td class="px-4 py-2 text-sm text-gray-800">
                {{ item.billingType === "collected" ? $t("subscription") : $t("termination") }}
              </td>
              <td class="px-4 py-2 text-sm text-gray-800">{{ item.date }}</td>
              <td class="px-4 py-2 text-sm text-right text-gray-800">{{ formatPrice(item.price) }}</td>
              <td class="px-4 py-2 text-sm text-right text-gray-800">{{ formatPrice(item.storeCommissionAmount) }}</td>
              <td class="px-4 py-2 text-sm text-right text-gray-800">{{ formatPrice(item.amount) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </CollapsableContent>
  </div>
</template>
<script>
import { CollapsableContent } from "@estaly/ui";
import AdminService from "@/api/services/admin";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";

export default {
  components: {
    CollapsableContent,
  },
  data() {
    return {
      invoiceItems: null,
      statementAmount: null,
      billAmount: null,
    };
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filteredInvoiceItems() {
      return this.invoiceItems.filter((item) => item.storeCommissionAmount > 0);
    },
    statementInvoiceItems() {
      return this.filteredInvoiceItems.filter((item) => item.transactionType === "statement");
    },
    billInvoiceItems() {
      return this.filteredInvoiceItems.filter((item) => item.transactionType === "bill");
    },
  },
  mounted() {
    this.getInvoiceItems();
  },
  methods: {
    formatPrice,
    async getInvoiceItems() {
      try {
        const response = await AdminService.getInvoiceItems(this.invoice.id);
        this.invoiceItems = response.data.invoiceItems;
        this.statementAmount = response.data.statementAmount;
        this.billAmount = response.data.billAmount;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
